import React from 'react';
import { Input } from '@amzn/awsui-components-react';
import HelpInfoLink from '../help/HelpInfoLink';
import { interludeAssociationsHelp } from '../help/HelpContent';

export interface Props {
    interlude: any;
    initial?: any;
    isEdit?: boolean;
    onChange: (e: CustomEvent) => void;
}

export interface State {
    remove: { [index: string]: boolean | null };
}

class Associations extends React.Component<Props, State> {
    handleChange = (e: CustomEvent) => {
        this.props.onChange(e);
    };

    render() {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveValue = 'col-xxxs-12 col-xxs-8';
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Associations</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Track ASINs:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="track_asin"
                            value={this.props.interlude.track_asin}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Album ASINs:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="album_asin"
                            value={this.props.interlude.album_asin}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Artist ASINs:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="artist_asin"
                            value={this.props.interlude.artist_asin}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Station Keys:</h4>
                        <Input
                            className={responsiveValue}
                            type="text"
                            id="stn_key"
                            value={this.props.interlude.stn_key}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="awsui-util-container-footer">
                    Associations restrict Interlude playback.
                    <HelpInfoLink content={interludeAssociationsHelp()} />
                </div>
            </div>
        );
    }
}

export default Associations;
