export interface InterludesUploadResponse {
    load_counts?: {
        interlude_definition_create_count: number;
        interlude_definition_create_range_start: string;
        interlude_definition_create_range_end: string;
        interlude_definition_update_count: number;
        entity_associations_create_count: number;
        entity_associations_update_count: number;
    };
}

export function renderInterludeResults(response?: InterludesUploadResponse): JSX.Element {
    const load_counts = response?.load_counts;
    return load_counts ? (
        <div className="awsui-util-spacing-v-s">
            <div>
                <div className="awsui-util-label">Interludes created</div>
                <div>{load_counts.interlude_definition_create_count}</div>
            </div>
            {load_counts.interlude_definition_create_count > 0 && (
                <div>
                    <div className="awsui-util-label">New interludes</div>
                    <div>
                        {load_counts.interlude_definition_create_count > 1
                            ? `${load_counts.interlude_definition_create_range_start} to ${load_counts.interlude_definition_create_range_end}`
                            : load_counts.interlude_definition_create_range_start}
                    </div>
                </div>
            )}
            <div>
                <div className="awsui-util-label">Interludes updated</div>
                <div>{load_counts.entity_associations_update_count}</div>
            </div>
            <div>
                <div className="awsui-util-label">Entity associations created</div>
                <div>{load_counts.entity_associations_create_count}</div>
            </div>
            <div>
                <div className="awsui-util-label">Entity associations updated</div>
                <div>{load_counts.entity_associations_update_count}</div>
            </div>
        </div>
    ) : (
        <div />
    );
}

export interface AssetUploadResponse {
    load_counts?: {
        text_asset_create_count: number;
        text_asset_create_range_start: string;
        text_asset_create_range_end: string;
        text_asset_update_count: number;
        media_asset_create_count: number;
        media_asset_create_range_start: string;
        media_asset_create_range_end: string;
        media_asset_update_count: number;
    };
}

export function renderAssetResults(response?: AssetUploadResponse): JSX.Element {
    const load_counts = response?.load_counts;
    return load_counts ? (
        <div className="awsui-util-spacing-v-s">
            <div>
                <div className="awsui-util-label">Text assets created</div>
                <div>{load_counts.text_asset_create_count}</div>
            </div>
            {load_counts.text_asset_create_count > 0 && (
                <div>
                    <div className="awsui-util-label">New text assets</div>
                    <div className="awsui-util-status-info">
                        {load_counts.text_asset_create_count > 1
                            ? `${load_counts.text_asset_create_range_start} to ${load_counts.text_asset_create_range_start}`
                            : load_counts.text_asset_create_range_start}
                    </div>
                </div>
            )}
            <div>
                <div className="awsui-util-label">Text assets updated</div>
                <div>{load_counts.text_asset_update_count}</div>
            </div>
            <div>
                <div className="awsui-util-label">Media assets created</div>
                <div>{load_counts.media_asset_create_count}</div>
            </div>
            {load_counts.media_asset_create_count > 0 && (
                <div>
                    <div className="awsui-util-label">New media assets</div>
                    <div>
                        {load_counts.media_asset_create_count > 1
                            ? `${load_counts.media_asset_create_range_start} to ${load_counts.media_asset_create_range_end}`
                            : load_counts.media_asset_create_range_start}
                    </div>
                </div>
            )}
            <div>
                <div className="awsui-util-label">Media assets updated</div>
                <div>{load_counts.media_asset_update_count}</div>
            </div>
        </div>
    ) : (
        <div></div>
    );
}
