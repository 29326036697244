/**
 * Used to convert fields from string to their desired native type (boolean or number)
 */
function prepareObject(object, opts) {
    opts = opts || {};
    if (opts.booleanFields) {
        opts.booleanFields.forEach(field => {
            object[field] = object[field] === true || object[field] === 'true' || object[field] === 'True';
        })
    }
    if (opts.numberFields) {
        opts.numberFields.forEach(field => {
            const type = typeof(object[field]);
            switch (type) {
                case 'undefined':
                    break;
                case 'number':
                    if (!Number.isFinite(object[field])) {
                        delete object[field];
                    }
                    break;
                case 'string':
                    object[field] = parseInt(object[field]);
                    if (!Number.isFinite(object[field])) {
                        delete object[field];
                    }
                    break;
                default:
                    throw Error(`Unsupported type ${type} found in field ${field}`);
            }
        })
    }
    return object;
}

export function prepareInterlude(interlude) {
    return prepareObject(interlude, {
        booleanFields: ['is_skippable', 'is_explicit', 'is_mixable', 'is_edtrl'],
        numberFields: ['rank']
    })
}

export function prepareAsset(asset) {
    return prepareObject(asset, {
        booleanFields: ['auto_values']
    })
}

export function prepareFormData(presignledUrl) {
    const formData = new FormData();
    Object.keys(presignledUrl.fields).forEach(key => formData.append(key, presignledUrl.fields[key]));
    return formData;
}

/**
 * Replaces each attribute in the input object with a wrapper object with a 'value' set to the original field value
 * The result is in the format that is expected by the DDB client used in the back-end lambda
 */
export function wrapValues(object) {
    for (const [key, value] of Object.entries(object)) {
        object[key] = {Value: value}
    }
    return object;
}

export function deepCopy(originalObject) {
    return JSON.parse(JSON.stringify(originalObject))
}