import stage from '../configs/stage';

const FORGE_APIG_ENDPOINTS = {
    test: 'https://h4nbe9nutc.execute-api.us-east-1.amazonaws.com/beta',
    beta: 'https://h4nbe9nutc.execute-api.us-east-1.amazonaws.com/beta',
    gamma: 'https://bs5jk2rkp0.execute-api.us-east-1.amazonaws.com/gamma',
    prod: 'https://r55efra5vd.execute-api.us-east-1.amazonaws.com/prod',

    // Dev stack configuration
    'mandhis-alpha': 'https://dbwov2mbi1.execute-api.us-east-1.amazonaws.com/alpha/'
}

export function getAPIGEndpoint() {
    return FORGE_APIG_ENDPOINTS[stage()] || FORGE_APIG_ENDPOINTS['gamma'];
}
