import { Link } from 'react-router-dom';
import { HelpPanel, HelpPanelFooterLinks } from './HelpPanel';

// Help content for different sections of the website

export function bulkUploadHelp() {
    return (
        <HelpPanel header="Upload guidelines">
            <p>Use only .csv metadata files for bulk uploading of Interlude and Asset definitions.</p>
            <p>
                You can generate an example CSV file using the &quot;Export&quot; option from either of the{' '}
                <Link to="/interludes">Interludes</Link> or <Link to="/assets">Assets</Link> tables, open in your
                favorite editor and replace the information as needed.
            </p>
            <p>You may select one or more files to upload at once.</p>
        </HelpPanel>
    );
}

export function interludeAssociationsHelp() {
    return (
        <HelpPanel header="Association guidelines">
            <h3>Overview</h3>
            <p>Associations define where in a customer's queue and on what station(s) an Interlude can be played.</p>
            <p>
                For example, if an Interlude is created with a list of Artist ASIN associations, then the Interlude
                <strong> can only be</strong> played adjacent to a Track that has at least one of the specified Artists.
            </p>
            <p>
                If an Interlude is created with a list of Station Key associations, then the Interlude
                <strong> can only be</strong> played on the specified stations.
            </p>
            <h3>Input</h3>
            <p>
                A list can be provided for each association <strong>type</strong>. Commas, spaces, or both can be used
                to separate list items.
            </p>
            <h3>Please note!</h3>
            <p>
                When no value is provided for an association <strong>type</strong>, then the Interlude has{' '}
                <strong>NO</strong> restrictions for that <strong>type</strong> of association.
            </p>
            <p>
                For example, if no value is provided for <strong>Station Keys</strong>, then the Interlude can be played
                on <strong>any</strong> station - with respect to any other <strong>type </strong>
                of association(s) that may have been provided.
            </p>
            <h3>Editing Associations</h3>
            <p>
                On the <strong>Edit Interlude</strong> page, update the association fields as necessary and click{' '}
                <strong>Save</strong>.
            </p>
            <p>
                When you need to remove an association <strong>type</strong>, remove everything from the respective
                field and click <strong>Save</strong>.
            </p>
            <h3>Wildcards</h3>
            <p>
                If you need to create an Interlude that can be played anywhere in a customer's queue and on any station,
                then create an Interlude such that the <strong>only</strong> association provided is an asterisk in the{' '}
                <strong>Track ASINs</strong> field.
            </p>
        </HelpPanel>
    );
}

export function replacementTokensHelp() {
    const links = [
        {
            url: 'https://w.amazon.com/bin/view/Amazon_Music/Storm/Tech/FieldTokens/',
            label: 'Field tokens',
            external: true,
        },
        {
            url: 'https://w.amazon.com/bin/view/Amazon_Music/Storm/Tech/InterludesV1/#HTTSVariables',
            label: 'TTS variables',
            external: true,
        },
    ];
    return (
        <HelpPanel header="Replacement tokens">
            <p>
                Replacement tokens are substituted at runtime based on the tracks surrounding the interlude. Tokens
                contain a reference to a track and the metadata for that track used as replacement. Position and
                metadata references are separated by an underscode character. To include a token in a template or
                display string, surround its reference with curly braces, for example:
                <code>{'{prev_track_short_title}'}</code>
            </p>
            <h3>Track position references</h3>
            <p>
                Use the syntax <code>prev</code> or <code>next</code> to reference tracks immediately before or after an
                interlude. Use the syntax <code>prev(#)</code> or <code>next(#)</code>) to reference tracks beyond
                those. Note that <code>prev(1)</code> is equivalent to <code>prev</code> and <code>next(1)</code> is
                equivalent to <code>next</code>
            </p>
            <h3>Metadata references</h3>
            <h4>Aggregates</h4>
            <ul>
                <li>
                    <code>track_short_title</code> The short version of the track title, example: "Blinding Lights"
                </li>
                <li>
                    <code>album_short_title</code> The short version of the album title, example: "After Hours"
                </li>
                <li>
                    <code>concatenated_artist_name</code> The name of the artist(s) associated with the track, example:
                    "The Weeknd"
                </li>
                <li>
                    <code>album_local_original_release_date</code> The date the track was released
                </li>
                <li>
                    <code>album_local_original_release_date-[dateformat]</code> *See "Date formats" section below
                </li>
            </ul>
            <h4>Charts</h4>
            <ul>
                <li>
                    <code>charts_rank</code> Rank of the track within the charts, example: "4"
                </li>
                <li>
                    <code>charts_type</code> Type of the charts, example: "Billboards"
                </li>
                <li>
                    <code>charts_category</code> Subtype of the charts, example: "Top 100"
                </li>
                <li>
                    <code>charts_period</code> Tracking period, example: "week"
                </li>
                <li>
                    <code>charts_period_count</code> Longest duration at this rank within the charts
                </li>
                <li>
                    <code>charts_start_of_period</code> When it first appeared at this rank in the charts
                </li>
                <li>
                    <code>charts_start_of_period-[dateformat]</code> *See "Date formats" section below
                </li>
            </ul>
            <h3>Date formats</h3>
            <p>
                Certain metadata tokens that reference a date can be custom-formatted by specifying a suffix of the form{' '}
                <code>-[format]</code> that combines one or more of the following: <code>y</code> (year),
                <code>m</code> (month) or <code>d</code> (day).
            </p>
            <p>
                For example: <code>next_charts_start_of_period-d</code> will read the day when the next track first
                appeared in its current rank within the charts.
            </p>
            <HelpPanelFooterLinks links={links} />
        </HelpPanel>
    );
}
