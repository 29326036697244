import React from 'react';
import { Flashbar, Input } from '@amzn/awsui-components-react';
import { fetchGetAsset } from '../../utils/fetchUtil';
import { itemError, itemInfo, itemLoading } from '../commons/flash-messages';

export interface Props {
    interlude: any;
    initial?: any;
    isEdit?: boolean;
    fillAssetDetails: (asset: any) => boolean;
    onChange: (e: CustomEvent) => void;
}

export interface State {
    flashbar: Flashbar.MessageDefinition[];
}

class AssetsSimple extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            flashbar: [],
        };
    }

    handleChange = (e: CustomEvent) => {
        this.props.onChange(e);
        this.validateAssets(e.detail.value);
    };

    validateAssets(str: string) {
        const assets = str.split(',');
        const promises: Promise<any>[] = [];
        const flashbar: Flashbar.MessageDefinition[] = [];
        assets.forEach((asset) => {
            if (!asset.trim()) {
                return;
            }
            const promise = fetchGetAsset(asset).catch((error) =>
                flashbar.push(itemError(`Failed to validate asset: ${asset}`, error)),
            );
            promises.push(promise);
        });
        if (promises.length == 0) {
            return;
        }
        this.setState({ flashbar: [itemLoading('Validating assets ...')] });
        Promise.all(promises).then((assets) => {
            let filled = false;
            const state = { flashbar: flashbar };
            assets.forEach((asset) => (filled = filled || this.props.fillAssetDetails(asset)));
            if (filled) {
                flashbar.push(itemInfo('Display text information was updated from asset metadata'));
            }
            this.setState(state);
        });
    }

    render() {
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveField = 'col-xxxs-12 col-xxs-8';
        return (
            <div>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <div className="awsui-util-container">
                    <div className="awsui-util-container-header">Assets</div>
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <h4 className={responsiveLabel}>Asset ID:</h4>
                            <Input
                                className={responsiveField}
                                type="text"
                                id="asset_ids"
                                value={this.props.interlude.asset_ids}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="awsui-row">
                            <h4 className={responsiveLabel}>Image Asset ID:</h4>
                            <Input
                                className={responsiveField}
                                type="text"
                                id="imgs"
                                value={this.props.interlude.imgs}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AssetsSimple;
