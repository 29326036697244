import React from 'react';
import ViewAsset from './ViewAsset';

export interface EditAssetsTabProps {
    current: any;
}

class EditAssetsTab extends React.Component<EditAssetsTabProps> {
    render(): JSX.Element {
        return (
            <div>
                {this.props.current.assets.text.map((assetId: string) => (
                    <ViewAsset key={assetId} assetId={assetId} />
                ))}
                {this.props.current.assets.media.map((assetId: string) => (
                    <ViewAsset key={assetId} assetId={assetId} />
                ))}
                {this.props.current.assets.images.map((img: any) => (
                    <ViewAsset key={img.id} assetId={img.id} />
                ))}
            </div>
        );
    }
}

export default EditAssetsTab;
