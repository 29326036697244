import { CognitoAuth } from 'amazon-cognito-auth-js';
import { getAuthConfig } from "./AuthConfiguration.js";
import Constants from '../components/Constants';

export const AUTHENTICATING_STATE = "authenticating";


function getAuth() {
    const authConfig = getAuthConfig();
    const auth = new CognitoAuth(authConfig);
    // Make sure to turn on OAuth2 Authorization Code Grant flow
    auth.useCodeGrantFlow();
    return auth;
}

// This code removes the "?code=..." from the URL. It is because the grant code is not reusable. Sometimes
// the SDK will report weird messages because of using old grant code.
function removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.history.length > 0) {
        const newHref = window.location.href.split('?')[0];
        window.history.replaceState(undefined, 'Forge Interludes Manager', newHref);
    }
}

/**
 * Ensures the user is authenticated.
 * If authenticated, return auth object. Otherwise force authentication.
 * Auth object will be used to call API gateway
 */
export function ensureAuthenticated() {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        Constants.auth = auth;
        // Register callback functions
        auth.userhandler = {
            onFailure: (err) => {
                removeQueryFromLocation();
                reject(err);
            },
            onSuccess: (result) => {
                removeQueryFromLocation();
                resolve(auth);
            },
        }

        const href = window.location.href;
        let session = auth.getSignInUserSession();

        if (session.isValid()) {
            resolve(auth);
        } else if (href.indexOf('?') > 0) {
            // This is required because Cognito needs to get the authentication result from the query string
            // The parsing is done asynchronously, and the result will be passed to the userHandler.
            // Once the result is parsed, onSuccess userhandler will resolve the promise.
            auth.parseCognitoWebResponse(href);
        } else {
            // Cognito SDK will handle session refresh / authentication.
            session = auth.getSession();
        }
    });
}
