import React, { SyntheticEvent } from 'react';
import { Button, Flashbar, Form, FormSection, Modal, Spinner, Textarea } from '@amzn/awsui-components-react';
import Media from './assets/Media';
import Data from './assets/Data';
import { flattenChangeEvent } from './commons/events';
import { deepCopy, prepareFormData } from '../utils/jsonUtil';
import {
    fetchCreateAsset,
    fetchDeleteAsset,
    fetchGetAsset,
    fetchGetPresignedS3Url,
    fetchUpdateAsset,
} from '../utils/fetchUtil';
import { FlashbarItem, itemError, itemSuccess } from './commons/flash-messages';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader, { PageHeaderButton } from './PageHeader';
import { UploadButton } from './assets/UploadButton';
import HelpInfoLink from './help/HelpInfoLink';
import { replacementTokensHelp } from './help/HelpContent';

interface State {
    asset: any;
    initial: any;
    flashbar: FlashbarItem[];
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    hasFile: boolean;
    selectedFile: File | null;
    showSavePopup: boolean;
    showDeletePopup: boolean;
}

interface RouteParams {
    asset_id?: string;
}

interface Props extends RouteComponentProps<RouteParams> {
    isEdit?: boolean;
}

const INITIAL_STATE: State = {
    asset: {},
    flashbar: [],
    initial: null,
    loading: false,
    saving: false,
    deleting: false,
    hasFile: false,
    selectedFile: null,
    showSavePopup: false,
    showDeletePopup: false,
};
class EditCreateAsset extends React.Component<Props, State> {
    asset: any = null;
    asset_id?: string;
    file: File | null = null;
    isEdit = false;
    dataRef = React.createRef<Data>();

    constructor(props: Props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(): void {
        this.loadComponent();
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.match?.params?.asset_id !== this.props.match?.params?.asset_id) {
            // User navigated to a different URL that maps to the same component
            // React does not (by design) unload the component, so we need to cleanup the state
            this.loadComponent();
        }
    }

    loadComponent(): void {
        if (!this.props.isEdit) {
            this.asset = {};
        }
        this.setState(INITIAL_STATE);
        this.asset_id = this.props.match?.params?.asset_id;
        this.isEdit = !!this.asset_id;
        if (this.isEdit) {
            this.loadAssetDetails(this.asset_id).catch((e) =>
                this.setState({ flashbar: [itemError('Failed to load asset', e)] }),
            );
        }
    }

    loadAssetDetails = (asset_id?: string): Promise<void> => {
        if (!asset_id) {
            return Promise.reject('Invalid asset ID specified');
        }
        this.setState({ loading: true });
        return fetchGetAsset(asset_id)
            .then((asset) => {
                this.asset = asset;
                this.setState({ asset: deepCopy(asset), initial: deepCopy(asset) });
            })
            .finally(() => this.setState({ loading: false }));
    };

    handleChange = (e: CustomEvent): void => {
        const { id, value } = flattenChangeEvent(e);
        this.asset[id] = value;
        this.setState({ asset: deepCopy(this.asset) });
    };

    handleFileChange = (file: File[]): void => {
        this.file = file && file.length > 0 ? file[0] : null;
        this.setState({ hasFile: !!this.file, selectedFile: this.file });
        if (this.file) {
            this.asset.origfile = this.file.name;
            this.setState({ asset: deepCopy(this.asset) });
        }
    };

    handleImageLoaded = (e: SyntheticEvent<HTMLImageElement>): void => {
        if (this.state.hasFile) {
            this.asset.height_pixels = e.currentTarget.height;
            this.asset.width_pixels = e.currentTarget.width;
            this.setState({ asset: deepCopy(this.asset) });
        }
    };

    handleAuthorUpdate = (author: string): void => {
        this.asset.author = author;
        this.setState({ asset: deepCopy(this.asset) });
    };

    uploadMedia(): Promise<any> {
        if (!this.file) {
            return Promise.reject('Cannot upload without selecting a file first.');
        }
        const result = {
            filename: null,
            extension: this.file.name.split('.').pop(),
        };
        return fetchGetPresignedS3Url()
            .then((presignedUrl) => {
                result.filename = presignedUrl.file_name;
                const formData = prepareFormData(presignedUrl);
                formData.append('file', this.file as Blob);
                return fetch(presignedUrl.url, { method: 'POST', body: formData });
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to upload asset file: ' + response.statusText);
                }
                return result;
            });
    }

    createAsset = async (): Promise<void> => {
        const flashbar = [];
        this.setState({ saving: true });
        try {
            if (this.asset.type !== 'TTS') {
                try {
                    const { filename, extension } = await this.uploadMedia();
                    this.asset.s3_key = filename;
                    this.asset.extension = extension;
                    flashbar.push(itemSuccess('Media file uploaded successfully'));
                    this.setState({ flashbar: flashbar.concat() });
                } catch (e) {
                    this.setState({ flashbar: [itemError('Failed to upload media', e)] });
                    return;
                }
            }
            try {
                const id = await fetchCreateAsset(this.asset);
                this.file = null;
                this.asset = { type: this.asset.type };
                flashbar.push(
                    itemSuccess(`Asset ${id} created successfully`).withButton('View asset', () => {
                        this.props.history.push(`/assets/${id}`);
                    }),
                );
                this.setState({ flashbar: flashbar.concat(), selectedFile: null, asset: deepCopy(this.asset) });
                this.dataRef.current?.onAssetSaved();
            } catch (e) {
                flashbar.push(itemError('Failed to update asset metadata', e));
                this.setState({ flashbar: flashbar });
                return;
            }
        } finally {
            this.setState({ saving: false });
        }
    };

    saveAsset = async (): Promise<void> => {
        const flashbar = [];
        this.setState({ saving: true, showSavePopup: false });
        try {
            if (this.file) {
                try {
                    const { filename, extension } = await this.uploadMedia();
                    this.asset.fileName = filename;
                    this.asset.extension = extension;
                    flashbar.push(itemSuccess('Media file uploaded successfully'));
                    this.setState({ flashbar: deepCopy(flashbar) });
                } catch (e) {
                    this.setState({ flashbar: [itemError('Failed to upload media', e)] });
                    return;
                }
            }
            try {
                await fetchUpdateAsset(this.asset);
                this.file = null;
                flashbar.push(itemSuccess('Asset metadata updated successfully'));
                this.setState({ flashbar: deepCopy(flashbar), selectedFile: null });
                this.dataRef.current?.onAssetSaved();
            } catch (e) {
                flashbar.push(itemError('Failed to update asset metadata', e));
                this.setState({ flashbar: flashbar });
                return;
            }
            try {
                await this.loadAssetDetails(this.asset_id);
            } catch (e) {
                flashbar.push(itemError('Failed to reload asset', e));
                this.setState({ flashbar: flashbar });
                return;
            }
        } finally {
            this.setState({ saving: false });
        }
    };

    deleteAsset = (): Promise<void> => {
        this.setState({ deleting: true, showDeletePopup: false });
        return fetchDeleteAsset(this.asset_id).then(() => {
            this.props.history.push('/assets', { reload: true });
        });
    };

    renderForm(isEdit: boolean): JSX.Element {
        const hasMedia = this.state.asset.type === 'IMAGE' || this.state.asset.type === 'AUDIO';
        const hasTemplate = this.state.asset.type === 'TTS';
        const acceptType =
            this.state.asset.type === 'IMAGE' ? 'image/*' : this.state.asset.type === 'AUDIO' ? 'audio/*' : '';
        const responsiveColumn = 'col-xxxs-12 col-xs-6';
        return (
            <div className="awsui-grid">
                <div className="awsui-row">
                    <FormSection className={responsiveColumn} header="Information">
                        <Data
                            ref={this.dataRef}
                            asset={this.state.asset}
                            isEdit={isEdit}
                            readonly={false}
                            onChange={this.handleChange}
                            onAuthorUpdate={this.handleAuthorUpdate}
                        />
                    </FormSection>
                    {hasMedia && (
                        <FormSection
                            className={responsiveColumn}
                            header="Media"
                            footer={
                                <UploadButton
                                    variant="link"
                                    icon="file-open"
                                    accept={acceptType}
                                    multiple={false}
                                    onFileChange={this.handleFileChange}
                                >
                                    Select file
                                </UploadButton>
                            }
                        >
                            <Media
                                asset={this.state.asset}
                                selectedFile={this.state.selectedFile}
                                onImageLoaded={this.handleImageLoaded}
                            />
                        </FormSection>
                    )}
                    {hasTemplate && (
                        <FormSection
                            className={responsiveColumn}
                            header="Template"
                            footer={
                                <>
                                    You can use replacement tokens
                                    <HelpInfoLink content={replacementTokensHelp()} />
                                </>
                            }
                        >
                            <Textarea
                                value={this.state.asset.template}
                                id="template"
                                onChange={this.handleChange}
                                rows={7}
                            />
                        </FormSection>
                    )}
                </div>
            </div>
        );
    }

    render(): JSX.Element {
        const headerButtons: PageHeaderButton[] = [
            {
                text: 'Delete',
                disabled: this.state.saving,
                loading: this.state.deleting,
                onClick: () => this.setState({ showDeletePopup: true }),
            },
            {
                text: 'Save',
                disabled: this.state.deleting,
                loading: this.state.saving,
                variant: 'primary',
                onClick: () => this.setState({ showSavePopup: true }),
            },
        ];
        const formButtons = (
            <Button
                variant="primary"
                disabled={this.state.asset.type !== 'TTS' && !this.state.hasFile}
                onClick={this.createAsset}
                loading={this.state.saving}
            >
                Create
            </Button>
        );

        return (
            <div>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Modal
                    visible={this.state.showDeletePopup}
                    header="Delete this Asset?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showDeletePopup: false })}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.deleteAsset}>
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will delete the Asset record.
                </Modal>
                <Modal
                    visible={this.state.showSavePopup}
                    header="Save Asset?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showSavePopup: false })}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.saveAsset}>
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will update the Asset with the current specified information.
                </Modal>
                {this.isEdit &&
                    (this.state.loading ? (
                        <span className="awsui-util-status-inactive">
                            <Spinner /> Loading
                        </span>
                    ) : (
                        this.state.asset && (
                            <>
                                <PageHeader text={`Edit asset: ${this.state.asset.id}`} buttons={headerButtons} />
                                {this.renderForm(true)}
                            </>
                        )
                    ))}
                {!this.isEdit && (
                    <Form header="Create asset" actions={formButtons}>
                        {this.renderForm(false)}
                    </Form>
                )}
            </div>
        );
    }
}

export default EditCreateAsset;
