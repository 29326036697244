//are_vended: true
//   // c_id: "someCustomerId"
//   // dev_type: "someDeviceType"
//   // e_i_id: "newId692"
//   // id: "B001411WRE"
//   // id_type: "ASIN"
//   // int_after: []
//   // int_befor: ["ee62ac42-8344-439b-9de1-b9fe6e478fb9"]
//   // ord: 0
//   // q_id: "b05cda66-9ca9-472d-bb3f-2456be79a6610"
//   // ttl: 1606516914

export const SORTABLE_COLUMNS = [
    { id: 'ord', field: 'ord'  },
    { id: 'q_id', field: 'q_id'  },
    { id: 'id', field: 'id'  },
    { id: 'id_type', field: 'id_type'  },
];

export const FILTERING_FIELDS = [
    //"q_id" ,
    //"ord",
    //"id",
    //"id_type",
];


export const CONTENT_SELECTOR_OPTIONS = [
{
  label: 'Main Queue properties',
  options: [
      {id: 'ord', label: 'ord', editable: true, visible: true},
      {id: 'vended', label: 'Vended?', editable: true, visible: true},
      {id: 'id', label: 'ASIN', editable: true, visible: true},
      {id: 'id_type', label: 'ID Type', editable: true, visible: true},
      {id: 'content_type', label: 'Content Type', editable: true, visible: true},
      {id: 'position', label: 'Position', editable: true, visible: true},
      {id: 'template_id', label: 'Interlude', editable: true, visible: true},
      {id: 'asset_id', label: 'Asset', editable: true, visible: true},

  ]
}
];

export const PAGE_SELECTOR_OPTIONS = [
{ value: 10, label: '10 Assets' },
{ value: 15, label: '15 Assets' },
{ value: 30, label: '30 Assets' },
    { value: 100, label: '100 Assets' },


];


export const PROPERTY_FILTERING_I18N_CONSTANTS = {
placeholder: 'Filter distributions by property or value',
operationAndText: 'and',
operationNotAndText: 'and not',
operationOrText: 'or',
operationNotOrText: 'or not',
clearFiltersText: 'Clear filters',
groupPropertiesText: 'Properties',
groupValuesText: 'Values',
filteringEmpty: 'No results found',
filteringLabel: 'Filter distributions',
filteringStatusText: 'Loading'
};