import React from 'react';
import { Button, Flashbar, Modal, Spinner, Tabs } from '@amzn/awsui-components-react';
import { fetchGetInterlude, fetchUpdateInterlude, fetchDeleteInterlude } from '../utils/fetchUtil';
import { deepCopy } from '../utils/jsonUtil';
import EditDataTab from './interludes/EditDataTab';
import EditAssetsTab from './interludes/EditAssetsTab';
import { itemError, itemSuccess } from './commons/flash-messages';
import { flattenChangeEvent } from './commons/events';
import PageHeader, { PageHeaderButton } from './PageHeader';
import { RouteComponentProps } from 'react-router-dom';

interface State {
    initial?: any; // Values since last time interlude was fetched
    interlude?: any; // Current values based on UX changes
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    flashbar: Flashbar.MessageDefinition[];
    showDeletePopup: boolean;
    showSavePopup: boolean;
}

interface RouteParams {
    interlude_id: string;
}

class EditInterlude extends React.Component<RouteComponentProps<RouteParams>, State> {
    interlude_id = '';
    interlude: any;

    constructor(props: RouteComponentProps<RouteParams>) {
        super(props);
        this.state = {
            initial: null, // Values since last time interlude was fetched
            interlude: null, // Current values based on UX changes
            loading: false,
            saving: false,
            deleting: false,
            flashbar: [],
            showDeletePopup: false,
            showSavePopup: false,
        };
    }

    componentDidMount(): void {
        this.interlude_id = this.props.match.params.interlude_id;
        this.loadInterludeDetails(this.interlude_id).catch((error) =>
            this.setState({ flashbar: [itemError('Failed to load interlude', error)] }),
        );
    }

    loadInterludeDetails(interlude_id: string): Promise<void> {
        if (!interlude_id) {
            return Promise.reject('LoadInterludeDetails called without a valid ID');
        }
        this.setState({ loading: true });
        return fetchGetInterlude(interlude_id)
            .then((interlude) => {
                this.interlude = interlude;
                this.setState({ interlude: deepCopy(interlude), initial: deepCopy(interlude) });
            })
            .finally(() => this.setState({ loading: false }));
    }

    saveInterludeTemplate = (): Promise<void> => {
        this.setState({ saving: true, showSavePopup: false });
        return fetchUpdateInterlude(this.interlude)
            .then(() => {
                // Temporary attempt at a multi-item flashbar
                // Will later introduce a proper class to hide this complexity
                const flashbar = [itemSuccess('Interlude saved')];
                this.setState({ flashbar: flashbar });
                this.loadInterludeDetails(this.interlude?.id).catch((e) => {
                    flashbar.push(itemError('Failed to reload interlude', e));
                    this.setState({ flashbar: flashbar });
                });
            })
            .catch((e) => this.setState({ flashbar: [itemError('Failed to save interlude', e)] }))
            .finally(() => this.setState({ saving: false }));
    };

    deleteInterludeTemplate = (): Promise<void> => {
        this.setState({ deleting: true, showDeletePopup: false });
        return fetchDeleteInterlude(this.interlude_id)
            .then(() => {
                this.interlude = null;
                this.setState({ interlude: null, initial: null });
                this.props.history.push('/interludes', { reload: true });
            })
            .catch((e) => this.setState({ flashbar: [itemError('Failed to delete interlude', e)] }))
            .finally(() => this.setState({ deleting: false }));
    };

    handleChange = (e: CustomEvent): void => {
        const { id, value } = flattenChangeEvent(e);
        this.interlude[id] = value;
        this.interlude.assets = {
            text: (this.interlude.asset_ids as string)
                .split(',')
                .map((id: string) => (id.startsWith('TXT-') ? id : null))
                .filter((id: string | null) => id),
            media: (this.interlude.asset_ids as string)
                .split(',')
                .map((id: string) => (id.startsWith('MED-') ? id : null))
                .filter((id: string | null) => id),
            images: (this.interlude.imgs as string)
                .split(',')
                .map((id) => (id ? { id: id } : null))
                .filter((id) => id),
        };
        this.setState({ interlude: deepCopy(this.interlude) });
    };

    fillAssetDetails = (asset: any): boolean => {
        let filled = false;
        if (asset && asset.author && !this.interlude['pri_txt']) {
            filled = true;
            this.interlude['pri_txt'] = asset.author;
        }
        if (asset && asset.descr && !this.interlude['sec_txt']) {
            filled = true;
            this.interlude['sec_txt'] = asset.descr;
        }
        if (filled) {
            this.setState({ interlude: deepCopy(this.interlude) }); // Propagate the state change down to components
        }
        return filled;
    };

    render(): JSX.Element {
        const headerButtons: PageHeaderButton[] = [
            {
                text: 'Delete',
                disabled: this.state.saving,
                loading: this.state.deleting,
                onClick: () => this.setState({ showDeletePopup: true }),
            },
            {
                text: 'Save',
                disabled: this.state.deleting,
                loading: this.state.saving,
                variant: 'primary',
                onClick: () => this.setState({ showSavePopup: true }),
            },
        ];
        const tabs: Tabs.Tab[] = [
            {
                label: 'Interlude data',
                id: 'data',
                content: (
                    <EditDataTab
                        initial={this.state.initial}
                        current={this.state.interlude}
                        onChange={this.handleChange}
                        fillAssetDetails={this.fillAssetDetails}
                    />
                ),
            },
            {
                label: 'Assets',
                id: 'assets',
                content: <EditAssetsTab current={this.state.interlude} />,
            },
        ];
        return (
            <div className="awsui-grid">
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Modal
                    visible={this.state.showDeletePopup}
                    header="Delete Interlude Template?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showDeletePopup: false })}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.deleteInterludeTemplate}>
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will delete Interlude Template and linked associations.
                </Modal>
                <Modal
                    visible={this.state.showSavePopup}
                    header="Save Interlude Template?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showSavePopup: false })}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.saveInterludeTemplate}>
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will update the Interlude Template with the current specified information.
                </Modal>
                {this.state.loading ? (
                    <span className="awsui-util-status-inactive">
                        <Spinner /> Loading
                    </span>
                ) : (
                    this.state.interlude && (
                        <div>
                            <PageHeader text={`Edit interlude: ${this.state.interlude.id}`} buttons={headerButtons} />
                            <Tabs tabs={tabs} activeTabId="data"></Tabs>
                        </div>
                    )
                )}
            </div>
        );
    }
}
export default EditInterlude;
