import React from 'react';

interface Props {
    asset: any;
    className?: string;
    readonly?: boolean;
    selectedFile?: File | null;
    onImageLoaded?: React.ReactEventHandler<HTMLImageElement>;
}

interface State {
    fileUrl?: string;
}

interface Dimension {
    height: number;
    width: number;
}

class Media extends React.Component<Props, State> {
    imageRef = React.createRef<HTMLImageElement>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
        this.buildFileUrl();
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.selectedFile != this.props.selectedFile) {
            this.buildFileUrl();
        }
    }

    buildFileUrl(): void {
        if (this.props.selectedFile) {
            // Making a copy of the blob only clones the metadata, the actual bytes are read from file each time
            const blob = new Blob([this.props.selectedFile], { type: this.props.selectedFile.type });
            this.setState({ fileUrl: URL.createObjectURL(blob) });
        } else {
            this.setState({ fileUrl: this.props.asset.presigned_url });
        }
    }

    getImageDimension(): Dimension | null {
        return this.imageRef.current != null
            ? {
                  height: this.imageRef.current.height,
                  width: this.imageRef.current.width,
              }
            : null;
    }

    render(): JSX.Element {
        const imageStyle = {
            height: 'auto',
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
        };
        return (
            <div className={this.props.className || ''}>
                {this.props.asset.type === 'AUDIO' && this.state.fileUrl && (
                    <audio controls crossOrigin="anonymous" src={this.state.fileUrl}>
                        audio file
                    </audio>
                )}
                {this.props.asset.type === 'IMAGE' && this.state.fileUrl && (
                    <a href={this.state.fileUrl} target="_blank" rel="noreferrer">
                        <img
                            crossOrigin="anonymous"
                            alt="S3 Image not found"
                            ref={this.imageRef}
                            style={imageStyle}
                            src={this.state.fileUrl}
                            onLoad={this.props.onImageLoaded}
                        />
                    </a>
                )}
            </div>
        );
    }
}

export default Media;
