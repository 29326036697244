export const appLayoutLabels = {
  navigation: 'Side navigation',
  navigationToggle: 'Open side navigation',
  navigationClose: 'Close side navigation',
  notifications: 'Notifications',
  tools: 'Help panel',
  toolsToggle: 'Open help panel',
  toolsClose: 'Close help panel'
};

export const paginationLabels = {
  nextPageLabel: 'Next page',
  previousPageLabel: 'Previous page',
  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
};

export const distributionSelectionLabels = {
  itemSelectionLabel: (data, row) => `select ${row.id}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Distribution selection'
};

export const originsSelectionLabels = {
  itemSelectionLabel: (data, row) => `select ${row.name}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Origins selection'
};

export const behaviorsSelectionLabels = {
  itemSelectionLabel: (data, row) => `select path ${row.pathPattern} from origin ${row.origin}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Behaviors selection'
};

export const templateSelectionLabels = {
  itemSelectionLabel: (data, row) => `select ${row.name}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Logs selection'
};

const generateColumnLabel = ({ id, header }) => sortState => {
  const columnIsSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${typeof header === 'function' ? header() : header}, ${
    columnIsSorted ? `sorted ${ascending ? 'ascending' : 'descending'}` : 'not sorted'
  }.`;
};

export const addColumnSortLabels = (columns, sortColumns) =>
  columns.map(col => ({
    label: sortColumns.find(sortCol => sortCol.id === col.id) ? generateColumnLabel(col) : undefined,
    ...col
  }));
