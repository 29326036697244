import Constants from '../components/Constants';
import { getAPIGEndpoint } from '../configs/api-endpoints-config';
import { prepareAsset, prepareInterlude, wrapValues, deepCopy } from './jsonUtil';

/**
 * FetchAPI wrapper to call the Forge backend APIG endpoint
 * Performs basic error handling of the response based on HTTP status code
 */
export function fetchBase(method, api, body) {
    const url = getAPIGEndpoint() + api;
    const request = {
        method: method,
        headers: {
            Authorization: Constants.auth.getSignInUserSession().getIdToken().getJwtToken(),
        },
        body: body,
    };
    return fetch(url, request).then((response) => {
        if (!response.ok) {
            let reqid = response.headers.get('x-amzn-requestid');
            return response.text().then((text) => {
                console.error(`Server request failed: ${method}, ${api} => [${reqid}] ${text} (${response.status})`);
                throw new Error(text || response.statusText);
            });
        }
        return response;
    });
}

/**
 * Extends fetchBase() to extract a JSON object from the Response
 */
export function fetchJson(method, api, body) {
    return fetchBase(method, api, body).then((response) => response.json());
}

/**
 * Retrieves an interlude object from the server by ID
 */
export function fetchGetInterlude(interludeId) {
    return fetchJson('GET', `/api/interludes/manage?id=${interludeId}`).then((json) => {
        if (!json.id) {
            throw new Error(`Could not find interlude in server`);
        }
        const interlude = prepareInterlude(json);
        // Store the assets originally returned by the server in a separate field
        interlude.assets = {
            text: interlude.asset_ids ? interlude.asset_ids.text || [] : [],
            media: interlude.asset_ids ? interlude.asset_ids.media || [] : [],
            images: interlude.imgs || [],
        };
        // Fix the asset fields as they should be submitted on a subsequent POST using the existing fields
        interlude.asset_ids = interlude.assets.text.concat(interlude.assets.media).join(',');
        interlude.imgs = interlude.assets.images.map((img) => img.id).join(',');
        return interlude;
    });
}

/**
 * Creates an interlude object
 */
export function fetchCreateInterlude(interlude) {
    const body = JSON.stringify(prepareInterlude(deepCopy(interlude)));
    return fetchJson('PUT', '/api/interludes/manage', body).then((json) => {
        if (!json.id) {
            throw new Error(`Server did not return new interlude id`);
        }
        return json.id;
    });
}

/**
 * Creates an interlude object
 */
export function fetchUpdateInterlude(interlude) {
    const copy = deepCopy(interlude);
    delete copy.id;
    const body = JSON.stringify(wrapValues(prepareInterlude(copy)));
    return fetchJson('POST', `/api/interludes/manage?id=${interlude.id}`, body);
}

/**
 * Creates an interlude object
 */
export function fetchDeleteInterlude(interludeId) {
    return fetchBase('DELETE', `/api/interludes/manage?id=${interludeId}`);
}

/**
 * Retrieves an asset object from the server by ID
 */
export function fetchGetAsset(assetId) {
    return fetchJson('GET', `/api/assets/manage?id=${assetId}`).then((json) => {
        if (!json.id) {
            throw new Error('Could not find asset in server');
        }
        // The dimensions of media assets are included in the srcs array
        // Merging them with the rest of the top-level asset properties
        if (json.srcs) {
            json = { ...json.srcs[0], ...json };
        }
        return prepareAsset(json);
    });
}

export function fetchCreateAsset(asset) {
    const api = asset.type === 'TTS' ? '/api/assets/text' : '/api/assets/media';
    return fetchJson('POST', api, JSON.stringify(deepCopy(prepareAsset(asset)))).then((json) => {
        if (!json.id) {
            throw new Error(`Server did not return new asset id`);
        }
        return json.id;
    });
}

export function fetchUpdateAsset(asset) {
    const copy = deepCopy(asset);
    delete copy.id;
    const body = JSON.stringify(wrapValues(prepareAsset(copy)));
    return fetchJson('POST', `/api/assets/manage?id=${asset.id}`, body);
}

export function fetchDeleteAsset(assetId) {
    return fetchBase('DELETE', `/api/assets/manage?id=${assetId}`);
}

export function fetchGetPresignedS3Url() {
    return fetchJson('GET', '/api/assets/creates3presignedurl');
}

export function fetchGetArtistMetadata(asin, mtr) {
    return fetchJson('GET', `/api/metadata/artist?asin=${asin}&mtr=${mtr}`);
}

export function fetchUploadInterludes(data) {
    return fetchJson('POST', '/api/interludes/bulkupload', data);
}

export function fetchUploadAssets(data) {
    return fetchJson('POST', '/api/assets/bulkupload', data);
}
