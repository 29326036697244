const STAGE_MAPPING = {
  'localhost': 'test',
  'beta.forge.music.amazon.dev': 'beta',
  'gamma.forge.music.amazon.dev': 'gamma',
  'forge.music.amazon.dev': 'prod',
};

export default () => {
  const currentHostName = window.location.hostname;
  let stage = STAGE_MAPPING[currentHostName];
  if (!stage && currentHostName.endsWith('.alpha.forge.music.amazon.dev')) {
    stage = currentHostName.split('.')[0] + '-alpha';
  }
  return stage;
};