import Constants from '../components/Constants';
import { getAPIGEndpoint } from '../configs/api-endpoints-config';

let items = [];
let filteringOptions = [];

const interlude_filtering_options = [
    {
        propertyLabel: 'Asset IDs',
        propertyKey: 'asset_ids',
        groupValuesLabel: 'Asset IDs',
        values: [],
    },
    {
        propertyLabel: 'Image',
        propertyKey: 'imgs',
        groupValuesLabel: 'Image',
        values: [],
    },
    {
        propertyLabel: 'Interlude ID',
        propertyKey: 'id',
        groupValuesLabel: 'Interlude ID',
        values: [],
    },
    {
        propertyLabel: 'Assembly Type',
        propertyKey: 'a_type',
        groupValuesLabel: 'Assembly Type',
        values: [],
    },
    {
        propertyLabel: 'Available Languages',
        propertyKey: 'lang',
        groupValuesLabel: 'Available Languages',
        values: [],
    },
    {
        propertyLabel: 'Available Territories',
        propertyKey: 'mtr_list',
        groupValuesLabel: 'Available Territories',
        values: [],
    },
    {
        propertyLabel: 'Content Source',
        propertyKey: 'src',
        groupValuesLabel: 'Content Source',
        values: [],
    },
    {
        propertyLabel: 'Content Type',
        propertyKey: 'c_type',
        groupValuesLabel: 'Content Type',
        values: [],
    },
    {
        propertyLabel: 'Creation Date Time',
        propertyKey: 'crt_time',
        groupValuesLabel: 'Creation Date Time',
        values: [],
    },
    {
        propertyLabel: 'Description',
        propertyKey: 'descr',
        groupValuesLabel: 'Description',
        values: [],
    },
    {
        propertyLabel: 'Is Editorial',
        propertyKey: 'is_edtrl',
        groupValuesLabel: 'Is Editorial',
        values: [],
    },
    {
        propertyLabel: 'Position',
        propertyKey: 'pos',
        groupValuesLabel: 'Position',
        values: [],
    },
    {
        propertyLabel: 'Rank',
        propertyKey: 'rank',
        groupValuesLabel: 'Rank',
        values: [],
    },
    {
        propertyLabel: 'Service Tiers',
        propertyKey: 'tiers',
        groupValuesLabel: 'Service Tiers',
        values: [],
    },
];

function createComparator(options) {
    const qualifier = options.sortingDescending ? -1 : 1;
    const field = options.sortingColumn;
    return (a, b) => (a[field] > b[field] ? qualifier : -qualifier);
}

function filterItemsByProperty(options) {
    const operationFn = (operation) => {
        switch (operation) {
            case 'or':
                return (value1, value2) => value1 || value2;
            case 'and':
            default:
                return (value1, value2) => value1 && value2;
        }
    };

    const match = (propertyKey, searchText, item, compareFn) => {
        // specific tag
        if (propertyKey.startsWith('tag-indicator__')) {
            const tagKey = propertyKey.split('__')[1];

            if (item.tags[tagKey]) {
                return item.tags[tagKey].some((tag) => compareFn(tag, searchText));
            }

            return false;
        }

        // all tags
        if (propertyKey === 'tags') {
            return (
                item.tags &&
                Object.keys(item.tags).some((tagKey) =>
                    item.tags[tagKey].some((tagValue) => compareFn(tagValue, searchText)),
                )
            );
        }

        // a property
        return compareFn(item[propertyKey], searchText);
    };

    const filteringFunction = function (item, tokens, operation) {
        const fullMatchStrategy = (a, b) => a === b;
        const freeTextStrategy = (a, b) => a.toLowerCase().indexOf(b.toLowerCase()) !== -1;

        const opFn = operationFn(operation);
        return tokens.reduce((include, token) => {
            const matchingStrategy = token.isFreeText ? freeTextStrategy : fullMatchStrategy;
            const searchableProps = token.propertyKey ? [token.propertyKey] : Object.keys(item);

            return searchableProps.some((propertyKey) => {
                const matched = match(propertyKey, token.value, item, matchingStrategy);
                return opFn(include, token.negated ? !matched : matched);
            });
        }, operation === 'and');
    };

    return items.filter((item) => filteringFunction(item, options.filteringTokens, options.filteringOperation));
}

function filterItemsByText(options) {
    return items.filter((item) => {
        for (const prop in item) {
            var textField = '';
            if (typeof item[prop] !== 'string') {
                // we search in Objects

                if (Array.isArray(item[prop])) {
                    for (const sub_item in item[prop]) {
                        if (typeof item[prop][sub_item] == 'string') {
                            textField = textField + item[prop][sub_item];
                        } else if (prop.includes('imgs')) {
                            textField = textField + item[prop][sub_item]['id'];
                        }
                    }
                } else if (prop.includes('asset_ids')) {
                    if ('media' in item[prop]) {
                        textField = textField + item[prop]['media'];
                    } else if ('text' in item[prop]) {
                        textField = textField + item[prop]['text'];
                    }
                } else {
                    continue;
                }
            } else {
                textField = item[prop];
            }
            let filteringText = options.filteringText;

            if (options.filteringPropertyKey) {
                filteringText = filteringText.substr(options.filteringPropertyKey.length + 2);
            }

            const matchesText = textField && textField.toLowerCase().indexOf(filteringText.toLowerCase()) !== -1;
            let matchesProp = false;

            if (options.filteringOptions) {
                matchesProp =
                    Object.prototype.hasOwnProperty.call(item, prop) &&
                    prop.toLowerCase().indexOf(filteringText.toLowerCase()) !== -1;
            }

            if (matchesText || matchesProp) {
                return true;
            }
        }
        return false;
    });
}

function filterItems(options) {
    return options.filteringTokens ? filterItemsByProperty(options) : filterItemsByText(options);
}

function prepareResponse(options) {
    const output = {};
    const shouldFilter =
        (options.filteringText && !options.filteringTokens) ||
        (options.filteringTokens && options.filteringTokens.length);
    const shouldFilterFilteringOptions =
        (options.filteringOptions || options.filteringPropertyKey) && options.filteringText;

    output.items = shouldFilter ? filterItems(options) : items.slice();

    if (shouldFilterFilteringOptions) {
        let hydratedFilteringOptions = options.filteringOptions.slice();

        if (options.filteringPropertyKey && !options.filteringText) {
            hydratedFilteringOptions = hydratedFilteringOptions.filter(
                (filteringOption) => filteringOption.propertyKey === options.filteringPropertyKey,
            );

            hydratedFilteringOptions[0].values = items.map((item) => item[options.filteringPropertyKey]);
        } else {
            filterItemsByText(options).forEach((item) => {
                for (let i = 0; i < hydratedFilteringOptions.length; i++) {
                    const propertyKey = hydratedFilteringOptions[i].propertyKey;

                    if (!hydratedFilteringOptions[i].values) {
                        hydratedFilteringOptions[i].values = [];
                    }

                    if (propertyKey.startsWith('tag-indicator__')) {
                        const tagKey = propertyKey.split('__')[1];
                        if (item.tags[tagKey]) {
                            hydratedFilteringOptions[i].values.push(...item.tags[tagKey]);
                        }
                    } else {
                        hydratedFilteringOptions[i].values.push(item[propertyKey]);
                    }
                }
            });
        }

        output.filteringOptions = hydratedFilteringOptions;
    } else {
        output.filteringOptions = filteringOptions;
    }

    if (options.sortingColumn) {
        output.items.sort(createComparator(options));
    }

    if (options.pageSize && options.currentPageIndex) {
        const pageSize = options.pageSize;
        const currentItems = output.items;
        let currentPageIndex = options.currentPageIndex;
        if ((currentPageIndex - 1) * pageSize >= currentItems.length) {
            currentPageIndex = 1;
        }

        output.pagesCount = Math.ceil(currentItems.length / pageSize);
        output.currentPageIndex = currentPageIndex;
        output.items = currentItems.slice((currentPageIndex - 1) * pageSize, currentPageIndex * pageSize);
    } else {
        output.pagesCount = 1;
        output.currentPageIndex = 1;
    }

    return output;
}

export function fetchInterlude(options = {}, callback, errorcb) {
    const url = getAPIGEndpoint() + '/api/interludes/';
    //console.log(Constants.auth.getSignInUserSession().getIdToken().getJwtToken())
    if (items.length === 0 || options.reload) {
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: Constants.auth.getSignInUserSession().getIdToken().getJwtToken(),
            },
        })
            .then((response) => {
                if (!response.ok) throw new Error('Interludes fetch failed');
                return response.json();
            })
            .then((json) => {
                items = json.filter((item) => item.id !== '_next_index');
                if (options.filteringOptions) {
                    fetchInterludeFilteringOptions(options, callback);
                } else {
                    setTimeout(() => callback(prepareResponse(options)), 1000);
                }
            })
            .catch((error) => {
                if (errorcb) {
                    errorcb(error);
                }
            });
    } else {
        setTimeout(() => callback(prepareResponse(options)), 1000);
    }
}

export function fetchInterludeFilteringOptions(options, callback) {
    Promise.resolve(interlude_filtering_options).then((response) => {
        filteringOptions = response;
        setTimeout(() => callback(prepareResponse(options)), 500);
    });
}
