export function flattenChangeEvent(change) {
    const flattened = { id: change.target.id, value: null }
    if ('value' in change.detail) {
        flattened.value = change.detail.value;
    } else if ('checked' in change.detail) {
        flattened.value = change.detail.checked;
    } else if ('selectedIds' in change.detail) {
        flattened.value = change.detail.selectedIds;
    } else if ('selectedId' in change.detail) {
        flattened.value = change.detail.selectedId;
    }
    return flattened;
}