export const SORTABLE_COLUMNS = [
    { id: 'id', field: 'id'  },
    { id: 'author', field: 'author'  },
    { id: 'lang', field: 'lang'  },
    { id: 'mtr', field: 'mtr' },
    { id: 'srcs', field: 'srcs'  },
    { id: 'type', field: 'type'  },
    { id: 'descr', field: 'descr' },
    { id: 'origfile', field: 'origfile' },
    { id: 'template', field: 'template' },
];

export const FILTERING_FIELDS = [
    "id" ,
    "a_type",
    "asset_ids",
    "imgs",
    "author",
    "lang",
    "mtr_list",
    "src",
    "c_type",
    "pos",
    "type",
    "template",
    "crt_time",
    "descr",
    "origfile"
];


export const CONTENT_SELECTOR_OPTIONS = [
{
  label: 'Main Asset properties',
  options: [
      {id: 'id', label: 'Asset ID', editable: true, visible: true},
      {id: 'author', label: 'Author', editable: true, visible: true},
      {id: 'descr', label: 'Description', editable: true, visible: true},
      {id: 'origfile', label: 'File Name', editable: true, visible: true},
      {id: 'lang', label: 'Languages', editable: true, visible: false},
      {id: 'mtr', label: 'Available Territories', editable: true, visible: false},
      {id: 'srcs', label: 'Sources', editable: true, visible: false},
      {id: 'type', label: 'Type', editable: true, visible: true},
      {id: 'template', label: 'Template', editable: true, visible: true},
  ]
}
];

export const PAGE_SELECTOR_OPTIONS = [
{ value: 10, label: '10 Assets' },
{ value: 15, label: '15 Assets' },
{ value: 30, label: '30 Assets' },
    { value: 100, label: '100 Assets' },
{ value: 500, label: '500 Assets' },
{ value: 1000, label: '1000 Assets' },

];


export const PROPERTY_FILTERING_I18N_CONSTANTS = {
placeholder: 'Filter distributions by property or value',
operationAndText: 'and',
operationNotAndText: 'and not',
operationOrText: 'or',
operationNotOrText: 'or not',
clearFiltersText: 'Clear filters',
groupPropertiesText: 'Properties',
groupValuesText: 'Values',
filteringEmpty: 'No results found',
filteringLabel: 'Filter distributions',
filteringStatusText: 'Loading'
};