import React from 'react';
import { getHeaderCounterText, getServerHeaderCounterText } from './tableCounterStrings';
import { successfulCreation } from './flash-messages';
import {Alert,Button,Flashbar,SideNavigation} from '@amzn/awsui-components-react';

export const FlashMessage = () => <Flashbar items={successfulCreation} />;

export const InfoLink = ({ onClick, id }) => (
  <a id={id} className="awsui-util-help-info-link" href="javascript:void(0);" onClick={onClick}>
    Info
  </a>
);

export const TableNoMatchState = props => (
  <div className="awsui-util-t-c awsui-util-mv-s">
    <b>No matches</b>
    <p className="awsui-util-mb-xs">We can't find a match.</p>
    <Button onClick={props.onClearFilter}>Clear filter</Button>
  </div>
);

export const TableEmptyState = props => (
  <div className="awsui-util-t-c awsui-util-mv-s">
    <b>No {props.resourceName.toLowerCase()}s</b>
    <p className="awsui-util-mb-xs">No {props.resourceName.toLowerCase()}s associated with this resource.</p>
    <Button>Create {props.resourceName.toLowerCase()}</Button>
  </div>
);

export const TableHeader = ({ ...props }) => {
  let counter = props.counter;
  if (!counter) {
    counter = props.totalItems
      ? props.serverSide
        ? getServerHeaderCounterText(props.totalItems, props.selectedItems)
        : getHeaderCounterText(props.totalItems, props.selectedItems)
      : null;
  }
  return (
    <div>
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <h2 className={props.updateTools ? 'awsui-util-d-ib' : null}>
            {`${props.title} `}
            {counter ? (
              <span id="custom-action-stripe-counter" className="awsui-util-header-counter">
                {`${counter}`}
              </span>
            ) : null}
          </h2>
          {props.updateTools ? <InfoLink onClick={props.updateTools} /> : null}
        </div>
        {props.actionButtons && <div className="awsui-util-action-stripe-group">{props.actionButtons}</div>}
      </div>
      {props.description && <div className="awsui-util-container-header-description">{props.description}</div>}
    </div>
  );
};

const defaultOnFollowHandler = ev => {
  // keep the locked href for our demo pages
  ev.preventDefault();
};
