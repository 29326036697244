import {getAPIGEndpoint} from "../configs/api-endpoints-config";
import Constants from "../components/Constants";


let items = [];
let filteringOptions = [];

const queue_filtering_options = [
  {
    "propertyLabel": "Ord",
    "propertyKey": "ord",
    "groupValuesLabel": "Ord",
    "values": []
  },
]

function filterItemsByText(options) {
  return items.filter(item => {
    for (const prop in item) {
      if (typeof item[prop] !== 'string') {
        // we search only in string properties;
        continue;
      }
      let filteringText = options.filteringText;

      if (options.filteringPropertyKey) {
        filteringText = filteringText.substr(options.filteringPropertyKey.length + 2);
      }

      const matchesText = item[prop] && item[prop].toLowerCase().indexOf(filteringText.toLowerCase()) !== -1;
      let matchesProp = false;

      if (options.filteringOptions) {
        matchesProp =
          Object.prototype.hasOwnProperty.call(item, prop) &&
          prop.toLowerCase().indexOf(filteringText.toLowerCase()) !== -1;
      }

      if (matchesText || matchesProp) {
        return true;
      }
    }
    return false;
  });
}

function filterItems(options) {
  return items;
  //return filterItemsByText(options);
}

function prepareResponse(options) {
  const output = {};
  const shouldFilter =
    (options.filteringText && !options.filteringTokens) || (options.filteringTokens && options.filteringTokens.length);
  const shouldFilterFilteringOptions =
    (options.filteringOptions || options.filteringPropertyKey) && options.filteringText;

  output.items = shouldFilter ? filterItems(options) : items.slice();

  if (shouldFilterFilteringOptions) {
    let hydratedFilteringOptions = options.filteringOptions.slice();

    if (options.filteringPropertyKey && !options.filteringText) {
      hydratedFilteringOptions = hydratedFilteringOptions.filter(
        filteringOption => filteringOption.propertyKey === options.filteringPropertyKey
      );

      hydratedFilteringOptions[0].values = items.map(item => item[options.filteringPropertyKey]);
    } else {
      filterItemsByText(options).forEach(item => {
        for (let i = 0; i < hydratedFilteringOptions.length; i++) {
          const propertyKey = hydratedFilteringOptions[i].propertyKey;

          if (!hydratedFilteringOptions[i].values) {
            hydratedFilteringOptions[i].values = [];
          }

          if (propertyKey.startsWith('tag-indicator__')) {
            const tagKey = propertyKey.split('__')[1];
            if (item.tags[tagKey]) {
              hydratedFilteringOptions[i].values.push(...item.tags[tagKey]);
            }
          } else {
            hydratedFilteringOptions[i].values.push(item[propertyKey]);
          }
        }
      });
    }

    output.filteringOptions = hydratedFilteringOptions;
  } else {
    output.filteringOptions = filteringOptions;
  }

  //if (options.sortingColumn) {
  //  output.items.sort(createComparator(options));
  //}

  if (options.pageSize && options.currentPageIndex) {
    const pageSize = options.pageSize;
    const currentItems = output.items;
    let currentPageIndex = options.currentPageIndex;
    if ((currentPageIndex - 1) * pageSize >= currentItems.length) {
      currentPageIndex = 1;
    }

    output.pagesCount = Math.ceil(currentItems.length / pageSize);
    output.currentPageIndex = currentPageIndex;
    output.items = currentItems.slice((currentPageIndex - 1) * pageSize, currentPageIndex * pageSize);
  } else {
    output.pagesCount = 1;
    output.currentPageIndex = 1;
  }

  return output;
}

export function fetchQueue(options = {}, callback) {
    if(!options.filteringText){
      return;
    }
  const url = getAPIGEndpoint() + '/api/queue/'
  //console.log(Constants.auth.getSignInUserSession().getIdToken().getJwtToken())
  if (options.filteringText.length > 10) {
    fetch(url + "?qid=" + options.filteringText, {
      method: 'GET',

      headers: {
        'Authorization': Constants.auth.getSignInUserSession().getIdToken().getJwtToken()
      }
    }).then(response => {
      if (!response.ok) throw new Error('Queue fetch failed');
      return response.json();
    })
      .then(json => {
        items = json.filter(item => (item.id !== '_next_index'))
        if (options.filteringOptions) {
            fetchQueueFilteringOptions(options, callback);
        } else {
          setTimeout(() => callback(prepareResponse(options)), 500);
        }
      });
  } //else {
    //setTimeout(() => callback(prepareResponse(options)), 500);
  //}
}

export function fetchQueueFilteringOptions(options, callback) {
    Promise.resolve(queue_filtering_options).then(response => {
    filteringOptions = response;
    setTimeout(() => callback(prepareResponse(options)), 500);
  });
}