import React from 'react';
import {
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS,
  FILTERING_FIELDS
} from '../configs/queue-table-config';
import {
  Table,
  TableContentSelector,
  TableFiltering,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  Checkbox,
  TableSorting,
  Modal,
  Button} from '@amzn/awsui-components-react';
import Constants from './/Constants'
import { TableNoMatchState } from './commons/common-components';
import {
  paginationLabels,
  templateSelectionLabels,
  addColumnSortLabels
} from './commons/labels';
import { getServerFilterCounterText } from './commons/tableCounterStrings';
import { createPropertyStorage } from './commons/createPropertyStorage';
import * as localStorage from './commons/localStorage';
import { mapWithColumnDefinitionIds, addToColumnDefinitions } from './commons/columnDefinitionsHelper';
import {fetchQueue} from "../utils/fetchQueue"
import {getAPIGEndpoint} from '../configs/api-endpoints-config'
const sortableColumns = SORTABLE_COLUMNS
const widthsStorage = createPropertyStorage('React-TableServerSide-Widths', localStorage);

import {CSVLink} from "react-csv";


export class QueueTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefinitions: this.COLUMN_DEFINITIONS,
      selectedAssets: [],
      items: [],
      pagesCount: 1,
      currentPageIndex: 1,
      filteringText: '',
      showDeleteModal: false,
      sortingDescending: false,
      pageSize: 15,
      loading: false,
      filteringDisabled: true,
      contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
      wrapLines: false,
      totalCount: 0,
      dataDownloaded: []
    };
    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.totalCalculated = false;
    this.csvLink = React.createRef();
  }


  COLUMN_DEFINITIONS = addColumnSortLabels(
    [
              {
      id: 'vended',
      header: 'Vended?',
      cell: item => (<Checkbox checked={item.are_vended ? true : false} disabled={true}/>),
      minWidth: '10px',
      allowLineWrap: false
    },
    {
      id: 'ord',
      header: 'Ord',
      cell: item => item.ord,
      minWidth: '10px',
      allowLineWrap: false
    },

      {
      id: 'id',
      header: 'ID',
      cell: item => (item.id ? item.id : item.rendered_interlude_id),
      minWidth: '30px',
      allowLineWrap: true
    },
                {
      id: 'content_type',
      header: 'Content Type',
      cell: item => item.content_type,
      minWidth: '30px',
      allowLineWrap: true
    },
      {
      id: 'int_befor',
      header: 'Before ID',
      cell: item => item.int_befor,
      minWidth: '30px',
      allowLineWrap: true
    },
        {
      id: 'int_after',
      header: 'After ID',
      cell: item => item.int_after,
      minWidth: '30px',
      allowLineWrap: true
    },
        {
      id: 'position',
      header: 'Position',
      cell: item => item.position,
      minWidth: '30px',
      allowLineWrap: true
    },
        {
      id: 'template_id',
      header: 'Interlude ID',
      cell: item => item.template_id,
      minWidth: '30px',
      allowLineWrap: true
    },
        {
      id: 'asset_id',
      header: 'Asset ID',
      cell: item => (item.assets ? item.assets[0].asset_id : ''),
      minWidth: '30px',
      allowLineWrap: true
    },


  ], sortableColumns);



 saveWidths = e => widthsStorage.save(mapWithColumnDefinitionIds(this.COLUMN_DEFINITIONS, 'width', e.detail.widths));
 loadWidths = () =>
  widthsStorage.load().then(columns => addToColumnDefinitions(this.COLUMN_DEFINITIONS, 'width', columns));

  componentDidMount() {
    //this.loadItems();
    this.loadWidths().then(columnDefinitions => {
      this.setState({
        columnDefinitions
      });
    });
  }

  loadItems() {
    this.setState({
      loading: true
    });
    const { filteringText } = this.state;
    fetchQueue(this.state, ({ items, pagesCount, currentPageIndex }) => {
      // Ignore the results if the filtering text has already changed while fetching these.
      if (filteringText && filteringText !== this.state.filteringText) {
        return;
      }

      const nextState = {
        loading: false,
        items: items,
        pagesCount,
        currentPageIndex
      };
      if (!this.totalCalculated) {
        nextState.totalCount = pagesCount > 1 ? this.state.pageSize * (pagesCount - 1) : nextState.items.length;
        this.totalCalculated = true;
      }
      this.setState(nextState);
    });
  }

  onPaginationChange(evt) {
    this.setState({
      pageSize: evt.detail.pageSize,
      currentPageIndex: evt.detail.currentPageIndex
    });
    this.loadItems();
  }

  onSortingChange(evt) {
    this.setState({
      sortingColumn: evt.detail.sortingColumn,
      sortingDescending: evt.detail.sortingDescending
    });
    this.loadItems();
  }

  onFilteringChange(evt) {
    this.setState({
      filteringText: evt.detail.filteringText,
      loading: false
    });
  }

  onFilteringDelayed() {
    this.loadItems();
  }


  onContentSelectionChange({ detail }) {
    const contentSelection = detail.contentSelection;
    const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
    this.setState({
      contentSelectorOptions: [
        {
          label: currentContentSelectorOptionGroup.label,
          options: currentContentSelectorOptionGroup.options.map(opt => ({
            id: opt.id,
            label: opt.label,
            editable: opt.editable,
            visible: contentSelection.indexOf(opt.id) !== -1
          }))
        }
      ]
    });
  }



  onWrapLinesChange({ detail }) {
    this.setState({
      wrapLines: detail.value
    });
  }

  filteringCountTextFunction() {
    const { items, pagesCount, pageSize } = this.state;
    return getServerFilterCounterText(items, pagesCount, pageSize);
  }

  clearFilter() {
    this.setState({
      filteringText: ''
    });
    this.loadItems();
  }

  render() {
    return (
      <div>
      <Table
        columnDefinitions={this.state.columnDefinitions}
        items={this.state.items}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={this.saveWidths}
        header={<h2>STORM Queue</h2>}
        loading={this.state.loading}
        loadingText="Loading Queue info"
        empty={<TableNoMatchState onClearFilter={this.clearFilter.bind(this)} />}
        wrapLines={this.state.wrapLines}
        onWrapLinesChange={this.onWrapLinesChange.bind(this)}
        onContentSelectionChange={this.onContentSelectionChange.bind(this)}
      >
        <TableFiltering
          filteringPlaceholder="Queue ID"
          filteringFields={FILTERING_FIELDS}
          filteringFunction={null}
          filteringText={this.state.filteringText}
          onFilteringChange={this.onFilteringChange.bind(this)}
          onFilteringDelayedInput={this.onFilteringDelayed.bind(this)}
          filteringCountTextFunction={this.filteringCountTextFunction.bind(this)}
        />

        <TablePagination
          labels={paginationLabels}
          currentPageIndex={this.state.currentPageIndex}
          pagesCount={this.state.pagesCount}
          onPaginationChange={this.onPaginationChange}
          disabled={this.state.loading}
          pageSize={this.state.pageSize}
        />
        <TableSorting
          sortingColumn={this.state.sortingColumn}
          sortableColumns={sortableColumns}
          disabled={this.state.loading}
          onSortingChange={this.onSortingChange.bind(this)}
        />

        <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel" disabled={this.state.loading}>
          <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
          <TableContentSelector title="Select visible columns" options={this.state.contentSelectorOptions} />
        </TablePreferences>
      </Table>
      </div>
    );
  }

}

export default QueueTable;