export const SORTABLE_COLUMNS = [
      { id: 'id', field: 'id'  },
      { id: 'a_type', field: 'a_type'  },
      { id: 'asset_ids', field: 'asset_ids'  },
      { id: 'imgs', field: 'imgs'  },
      { id: 'lang', field: 'lang'  },
      { id: 'mtr_list', field: 'mtr_list' },
      { id: 'src', field: 'src'  },
      { id: 'c_type', field: 'c_type'  },
      { id: 'crt_time', field: 'crt_time'},
      { id: 'descr', field: 'descr' },
      { id: 'is_edtrl', field: 'is_edtrl'},
      { id: 'pos', field: 'pos' },
      { id: 'rank', field: 'rank'},
      { id: 'tiers', field: 'tiers'},
      { id: 'is_explicit', field: 'is_explicit'},
      { id: 'is_mixable', field: 'is_mixable'},
      { id: 'is_skippable', field: 'is_skippable'},
      { id: 'dayOfWeekEnd', field: 'dayOfWeekEnd'},
      { id: 'dayOfWeekStart', field: 'dayOfWeekStart'},
];

export const FILTERING_FIELDS = [
  "id",
  "a_type",
  "asset_ids",
  "imgs",
  "lang",
  "mtr_list",
  "src",
  "c_type",
  "crt_time",
  "descr",
  "is_edtrl",
  "pos",
  "rank",
  "service_tiers",
  "is_explicit",
  "is_mixable",
  "is_skippable",
  "dayOfWeekEnd",
  "dayOfWeekStart",
];


export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main Interludes properties',
    options: [
      { id: 'id', label: 'Interlude ID', editable: false, visible: true },
      { id: 'a_type', label: 'Assembly Type', editable: true, visible: true },
      { id: 'asset_ids', label: 'Asset IDs', editable: true, visible: true },
      { id: 'imgs', label: 'Image Asset', editable: true, visible: true },
      { id: 'lang', label: 'Available Languages', editable: true, visible: true },
      { id: 'mtr_list', label: 'Available Territories', editable: true, visible: false },
      { id: 'src', label: 'Content Source', editable: true, visible: true },
      { id: 'c_type', label: 'Content Type', editable: true, visible: true },
      { id: 'crt_time', label: 'Creation Date Time', editable: true, visible: true },
      { id: 'descr', label: 'Description', editable: true, visible: true },
      { id: 'is_edtrl', label: 'Is Editorial', editable: true, visible: false },
      { id: 'service_tiers', label: 'Service Tiers', editable: true, visible: false },
      { id: 'pos', label: 'Position', editable: true, visible: false },
      { id: 'rank', label: 'Rank', editable: true, visible: false },
      { id: 'is_explicit', label: 'is_explicit', editable: true, visible: false},
      {id: 'is_mixable', label: 'is_mixable', editable: true, visible: false},
      {id: 'is_skippable', label: 'is_skippable', editable: true, visible: false},
      {id: 'dayOfWeekStart', label: 'dayOfWeekStart', editable: true, visible: false},
      {id: 'dayOfWeekEnd', label: 'dayOfWeekEnd', editable: true, visible: false}

    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Interludes' },
  { value: 15, label: '15 Interludes' },
  { value: 30, label: '30 Interludes' },
    { value: 100, label: '100 Interludes' },
{ value: 500, label: '500 Interludes' },
{ value: 1000, label: '1000 Interludes' },
];


export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  placeholder: 'Filter interludes by property or value',
  operationAndText: 'and',
  operationNotAndText: 'and not',
  operationOrText: 'or',
  operationNotOrText: 'or not',
  clearFiltersText: 'Clear filters',
  groupPropertiesText: 'Properties',
  groupValuesText: 'Values',
  filteringEmpty: 'No results found',
  filteringLabel: 'Filter distributions',
  filteringStatusText: 'Loading'
};