import { Select } from "@amzn/awsui-components-react"

export const service_tier_options: Select.Option[] = [
    {
        "label": "FREETIER",
        "id": "FREETIER",
        "description": "Amazon Music Free Tier"
    },
    {
        "label": "PRIME",
        "id": "PRIME",
        "description": "Prime Music tier"
    },
    {
        "label": "UNLIMITED",
        "id": "UNLIMITED",
        "description": "Amazon Music Unlimited"
    },
    {
        "label": "AMAZON MUSIC HD",
        "id": "AMAZON MUSIC HD",
        "description": "Amazon Music HD"
    },

]
export const service_tier_default: Select.Option[] = [
    {
        "label": "PRIME",
        "id": "PRIME",
        "description": "Prime Music tier"
    },
    {
        "label": "UNLIMITED",
        "id": "UNLIMITED",
        "description": "Amazon Music Unlimited"
    },
    {
        "label": "AMAZON MUSIC HD",
        "id": "AMAZON MUSIC HD",
        "description": "Amazon Music HD"
    },

]
export const available_language_options: Select.Option[] = [
    {
        "label": "en_US",
        "id": "en",
        "description": "English (US)"
    },
    {
        "label": "en_GB",
        "id": "en_GB",
        "description": "English (UK)"
    },

    {
        "label": "en_CA",
        "id": "en_CA",
        "description": "English (Canada)"
    },

    {
        "label": "pt_BR",
        "id": "pt_BR",
        "description": "Portuguese (Brazil)"
    },


    {
        "label": "fr_FR",
        "id": "fr_FR",
        "description": "French (France)"
    },

    {
        "label": "fr_CA",
        "id": "fr_CA",
        "description": "French (Canada)r"
    },
    {
        "label": "es_MX",
        "id": "es_MX",
        "description": "Spanish (Mexico)"
    },

    {
        "label": "es_US",
        "id": "es_US",
        "description": "Spanish (US)"
    },

    {
        "label": "es_ES",
        "id": "es",
        "description": "Spanish (Spain)"
    },


    {
        "label": "de_AT",
        "id": "de_AT",
        "description": "German (Austria)"
    },

    {
        "label": "en_IN",
        "id": "en_IN",
        "description": "English (India)"
    },

    {
        "label": "hi_IN",
        "id": "hi_IN",
        "description": "Hindi (India)"
    },
    {
        "label": "it_IT",
        "id": "it_IT",
        "description": "Italian (Italy)"
    },
    {
        "label": "ja_JP",
        "id": "ja_JP",
        "description": "Japanese (Japan)"
    },
    {
        "label": "de_DE",
        "id": "de_DE",
        "description": "German (Germany)"
    },



]

export const available_language_default: Select.Option[] = [
    {
        "label": "en_US",
        "id": "en",
        "description": "English (US)"
    },
]

export const available_market_options: Select.Option[] = [
    {
        "label": "US",
        "id": "US",
        "description": "United States"
    },
    {
        "label": "UK",
        "id": "UK",
        "description": "United Kingdom"
    },
    {
        "label": "DE",
        "id": "DE",
        "description": "Germany"
    },
    {
        "label": "JP",
        "id": "JP",
        "description": "Japan"
    },
    {
        "label": "FR",
        "id": "FR",
        "description": "France"
    },
    {
        "label": "IT",
        "id": "IT",
        "description": "Italy"
    },
    {
        "label": "ES",
        "id": "ES",
        "description": "Spain"
    },
    {
        "label": "CA",
        "id": "CA",
        "description": "Canada"
    },
    {
        "label": "MX",
        "id": "MX",
        "description": "Mexico"
    },

]

export const available_market_default: Select.Option[] = [
    {
        "label": "US",
        "id": "US",
        "description": "United States"
    }
]

export const day_list: Select.Option[] = [
    {
        "label": "Monday",
        "id": "Monday"
    },
    {
        "label": "Tuesday",
        "id": "Tuesday"
    },
    {
        "label": "Wednesday",
        "id": "Wednesday"
    },
    {
        "label": "Thursday",
        "id": "Thursday"
    },
    {
        "label": "Friday",
        "id": "Friday"
    },
    {
        "label": "Saturday",
        "id": "Saturday"
    },
    {
        "label": "Sunday",
        "id": "Sunday"
    },

]

export const position_list: Select.Option[] = [
    {
        "label": "INTRO",
        "id": "INTRO"
    },
    {
        "label": "OUTRO",
        "id": "OUTRO"
    },
    {
        "label": "BRIDGE",
        "id": "BRIDGE"
    },
]

export const asset_type: Select.Option[] = [
    {
        "label": "Audio",
        "id": "AUDIO"
    },
    {
        "label": "TTS",
        "id": "TTS"
    },
    {
        "label": "Image",
        "id": "IMAGE"
    },
]

export const assembly_type: Select.Option[] = [
    {
        "label": "Audio",
        "id": "AUDIO"
    },
    {
        "label": "TTS",
        "id": "TTS"
    },
]

export const content_type: Select.Option[] = [
    {
        label: 'BILLBOARD_CHARTS_SONG_EXACT_POSITION',
        id: 'BILLBOARD_CHARTS_SONG_EXACT_POSITION'
    },
    {
        label: 'QUEUE_ANNOUNCEMENT_ARTISTS',
        id: 'QUEUE_ANNOUNCEMENT_ARTISTS'
    },
    {
        label: 'QUEUE_ANNOUNCEMENT_SONGS',
        id: 'QUEUE_ANNOUNCEMENT_SONGS'
    },
    {
        label: 'MUSIC_PROGRAMMER',
        id: 'MUSIC_PROGRAMMER'
    },
    {
        label: 'SONG_ID',
        id: 'SONG_ID'
    },
    {
        label: 'ARTIST_FACTOIDS_PERENNIAL',
        id: 'ARTIST_FACTOIDS_PERENNIAL'
    },
    {
        label: 'SONG_FACTOIDS_PERENNIAL',
        id: 'SONG_FACTOIDS_PERENNIAL'
    },
    {
        label: 'STATION_ID_BUMPER',
        id: 'STATION_ID_BUMPER'
    },
    {
        label: 'ARTIST_FACTOIDS',
        id: 'ARTIST_FACTOIDS'
    },
    {
        label: 'SERVICE_ID_BUMPER',
        id: 'SERVICE_ID_BUMPER'
    },
    {
        label: 'ARTIST_VOICE',
        id: 'ARTIST_VOICE'
    },
    {
        label: 'RELEASE_DATE_EXACT',
        id: 'RELEASE_DATE_EXACT'
    },
    {
        label: 'SEGMENT_ID_A',
        id: 'SEGMENT_ID_A'
    },
    {
        label: 'SEGMENT_ID_B',
        id: 'SEGMENT_ID_B'
    },
    {
        label: 'SEGMENT_ID_C',
        id: 'SEGMENT_ID_C'
    },
    {
        label: 'SEGMENT_ID_D',
        id: 'SEGMENT_ID_D'
    },
    {
        label: 'SEGMENT_ID_E',
        id: 'SEGMENT_ID_E'
    },
    {
        label: 'SEGMENT_ID_F',
        id: 'SEGMENT_ID_F'
    },
    {
        label: 'SEGMENT_ID_G',
        id: 'SEGMENT_ID_G'
    },
    {
        label: 'SEGMENT_ID_H',
        id: 'SEGMENT_ID_H'
    },
    {
        label: 'SEGMENT_ID_I',
        id: 'SEGMENT_ID_I'
    },
    {
        label: 'SEGMENT_ID_J',
        id: 'SEGMENT_ID_J'
    },
    {
        label: 'CO_HOST_A',
        id: 'CO_HOST_A'
    },
    {
        label: 'CO_HOST_B',
        id: 'CO_HOST_B'
    },
    {
        label: 'CO_HOST_C',
        id: 'CO_HOST_C'
    }
]

export function getSelection(options: Select.Option[], id: string): Select.Option|null {
    if (!id) {
        return null;
    }
    let selection = null;
    options.forEach(option => {
        if (option.id === id) {
            selection = option;
        }
    })
    return selection;
}

export function getMultipleSelection(options: Select.Option[], ids: string[]): Select.Option[]{
    if(!ids || !Array.isArray(ids)){
        return [];
    }
    return ids.map((id) => getSelection(options, id) as Select.Option).filter(value => value != null);
}