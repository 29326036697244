import React from 'react';
import { Button, Flashbar, Form } from '@amzn/awsui-components-react';
import {
    service_tier_default,
    available_language_default,
    available_market_default,
} from '../configs/multi-select-config';
import AssetsSimple from './interludes/AssetsSimple';
import Associations from './interludes/Associations';
import DisplayText from './interludes/DisplayText';
import Eligibility from './interludes/Eligibility';
import Internal from './interludes/Internal';
import Sequencing from './interludes/Sequencing';
import { fetchCreateInterlude } from '../utils/fetchUtil';
import { deepCopy } from '../utils/jsonUtil';
import { flattenChangeEvent } from './commons/events';
import { RouteComponentProps } from 'react-router-dom';
import { FlashbarItem, itemError, itemLoading, itemSuccess } from './commons/flash-messages';

const rank_default = 1;

interface State {
    flashbar: FlashbarItem[];
    interlude: any;
}

class CreateInterlude extends React.Component<RouteComponentProps, State> {
    // This Interlude instance is used to update the DDB. The instance in state is used to update the UI when needed.
    interlude: any = {};

    constructor(props: RouteComponentProps) {
        super(props);
        this.interlude = {
            // Initialize default values
            rank: rank_default,
            tiers: service_tier_default.map((tier) => tier.id),
            lang: available_language_default.map((lang) => lang.id),
            mtr_list: available_market_default.map((mtr) => mtr.id),
        };
        this.state = {
            flashbar: [],
            interlude: deepCopy(this.interlude),
        };
    }

    handleChange = (e: CustomEvent): void => {
        const { id, value } = flattenChangeEvent(e);
        this.interlude[id] = value;
        this.setState({ interlude: deepCopy(this.interlude) }); // Propagate the state change down to components
    };

    fillAssetDetails = (asset: any): boolean => {
        let filled = false;
        if (asset && asset.author && !this.interlude['pri_txt']) {
            filled = true;
            this.interlude['pri_txt'] = asset.author;
        }
        if (asset && asset.descr && !this.interlude['sec_txt']) {
            filled = true;
            this.interlude['sec_txt'] = asset.descr;
        }
        if (filled) {
            this.setState({ interlude: deepCopy(this.interlude) }); // Propagate the state change down to components
        }
        return filled;
    };

    onInterludeCreate = (): Promise<void> => {
        this.setState({
            flashbar: [itemLoading('Creating interlude ...')],
        });
        return fetchCreateInterlude(this.interlude)
            .then((id) => {
                this.setState({
                    flashbar: [
                        itemSuccess(`Interlude ${id} created successfully`).withButton('View interlude', () => {
                            this.props.history.push(`/interludes/${id}`);
                        }),
                    ],
                });
            })
            .catch((error) => {
                this.setState({
                    flashbar: [itemError('Your interlude could not be created', error)],
                });
            });
    };

    render(): JSX.Element {
        const formButtons = (
            <Button variant="primary" onClick={this.onInterludeCreate}>
                Create
            </Button>
        );
        return (
            <div>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Form header="Create interlude" actions={formButtons}>
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <div className="col-xxxs-12 col-xs-6">
                                <Sequencing interlude={this.state.interlude} onChange={this.handleChange}></Sequencing>
                                <Associations
                                    interlude={this.state.interlude}
                                    onChange={this.handleChange}
                                ></Associations>
                                <AssetsSimple
                                    interlude={this.state.interlude}
                                    onChange={this.handleChange}
                                    fillAssetDetails={this.fillAssetDetails}
                                ></AssetsSimple>
                            </div>
                            <div className="col-xxxs-12 col-xs-6">
                                <Eligibility
                                    interlude={this.state.interlude}
                                    onChange={this.handleChange}
                                ></Eligibility>
                                <DisplayText
                                    interlude={this.state.interlude}
                                    onChange={this.handleChange}
                                ></DisplayText>
                                <Internal interlude={this.state.interlude} onChange={this.handleChange}></Internal>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}
export default CreateInterlude;
