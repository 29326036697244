import React from 'react';

export interface HelpContext {
    /**
     * Programmatically sets the content of the toolbox panel in the top-level AppLayout component
     * @param content the content to display in the toolbox panel
     * @param isPageDefault set to true to set the default panel content for the current page
     * @param openPanel set to true to immediately open the toolbox panel
     */
    setContent: (content: React.ReactNode, isPageDefault: boolean, openPanel: boolean) => void;
}

const defaultContext = {
    setContent: () => {},
};

export const HelpContext = React.createContext<HelpContext>(defaultContext);
