import React from 'react';
import {
    CONTENT_SELECTOR_OPTIONS,
    PAGE_SELECTOR_OPTIONS,
    SORTABLE_COLUMNS,
    FILTERING_FIELDS,
} from '../configs/interludes-table-config';
import {
    Table,
    TableContentSelector,
    TableFiltering,
    TablePageSizeSelector,
    TablePagination,
    TablePreferences,
    TableSelection,
    TableSorting,
    Modal,
    Button,
    Flashbar,
} from '@amzn/awsui-components-react';
import Constants from './Constants';
import { InterludeTableButtons } from './TableHeader';
import { TableNoMatchState } from './commons/common-components';
import { paginationLabels, templateSelectionLabels, addColumnSortLabels } from './commons/labels';
import { getServerFilterCounterText } from './commons/tableCounterStrings';
import { createPropertyStorage } from './commons/createPropertyStorage';
import * as localStorage from './commons/localStorage';
import { mapWithColumnDefinitionIds, addToColumnDefinitions } from './commons/columnDefinitionsHelper';
import { fetchInterlude } from '../utils/fetchInterludes';
import { getAPIGEndpoint } from '../configs/api-endpoints-config';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { itemError } from './commons/flash-messages';
const sortableColumns = SORTABLE_COLUMNS;
const widthsStorage = createPropertyStorage('React-TableServerSide-Widths', localStorage);

export class InterludesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefinitions: this.COLUMN_DEFINITIONS,
            selectedTemplates: [],
            templates: [],
            pagesCount: 1,
            currentPageIndex: 1,
            filteringText: '',
            showDeleteModal: false,
            sortingDescending: false,
            pageSize: 15,
            loading: true,
            filteringDisabled: true,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            wrapLines: false,
            totalCount: 0,
            dataToDownload: [],
            exporting: false,
            deleting: false,
            flashbar: [],
        };
        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.totalCalculated = false;
        this.finaldata = [];
    }

    csvLink = React.createRef();

    COLUMN_DEFINITIONS = addColumnSortLabels(
        [
            {
                id: 'id',
                header: () => 'Interlude ID',
                cell: (item) => (
                    <div>
                        <Link to={`/interludes/${item.id}`}>{item.id}</Link>
                    </div>
                ),
                minWidth: '180px',
                allowLineWrap: true,
            },
            {
                id: 'a_type',
                cell: (item) => item.a_type,
                header: () => 'Assembly Type',
                minWidth: '160px',
                allowLineWrap: true,
            },
            {
                id: 'asset_ids',
                cell: (item) =>
                    item.asset_ids
                        ? item.asset_ids.media
                            ? item.asset_ids.media.join(',')
                            : item.asset_ids.text
                            ? item.asset_ids.text.join(',')
                            : ''
                        : '',
                header: () => 'Asset IDs',
                minWidth: '160px',
                allowLineWrap: true,
            },
            {
                id: 'imgs',
                cell: (item) => (item.imgs ? item.imgs.map((img) => img.id).join(',') : ''),
                header: () => 'Image ID',
                minWidth: '160px',
                allowLineWrap: true,
            },
            {
                id: 'lang',
                header: () => 'Available Languages',
                cell: (item) => (item.lang ? item.lang.toString() : ''),
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'mtr_list',
                header: () => 'Available Territories',
                cell: (item) => (item.mtr_list ? item.mtr_list.toString() : ''),
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'src',
                header: () => 'Content Source',
                cell: (item) => item.src,
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'c_type',
                header: () => 'Content Type',
                cell: (item) => item.c_type,
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'crt_time',
                header: () => 'Creation Date Time',
                cell: (item) => item.crt_time,
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'descr',
                header: () => 'Description',
                cell: (item) => item.descr,
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'is_edtrl',
                header: () => 'Is Editorial',
                cell: (item) => (item.is_edtrl ? item.is_edtrl.toString() : 'false'),
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'pos',
                header: () => 'Position',
                cell: (item) => item.pos,
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'rank',
                header: () => 'Rank',
                cell: (item) => item.rank,
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'tiers',
                header: () => 'Service Tiers',
                cell: (item) => (item.tiers ? item.tiers.toString() : ''),
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'is_explicit',
                header: () => 'isExplicit',
                cell: (item) => (item.isExplicit ? item.isExplicit.toString() : 'false'),
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'is_mixable',
                header: () => 'isMixable',
                cell: (item) => (item.isMixable ? item.isMixable.toString() : 'false'),
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'is_skippable',
                header: () => 'isSkippable',
                cell: (item) => (item.isSkippable ? item.isSkippable.toString() : 'false'),
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'dayOfWeekEnd',
                header: () => 'dayOfWeekEnd',
                cell: (item) => (item.dayOfWeekEnd ? item.dayOfWeekEnd.toString() : ''),
                minWidth: '100px',
                allowLineWrap: true,
            },
            {
                id: 'dayOfWeekStart',
                header: () => 'dayOfWeekStart',
                cell: (item) => (item.dayOfWeekStart ? item.dayOfWeekStart.toString() : ''),
                minWidth: '100px',
                allowLineWrap: true,
            },
        ],
        sortableColumns,
    );

    saveWidths = (e) =>
        widthsStorage.save(mapWithColumnDefinitionIds(this.COLUMN_DEFINITIONS, 'width', e.detail.widths));
    loadWidths = () =>
        widthsStorage.load().then((columns) => addToColumnDefinitions(this.COLUMN_DEFINITIONS, 'width', columns));

    componentDidMount() {
        this.loadItems();
        this.loadWidths().then((columnDefinitions) => {
            this.setState({
                columnDefinitions,
            });
        });
    }

    loadItems(reload = false) {
        this.setState({
            loading: true,
        });
        const { filteringText } = this.state;
        const options = { ...this.state };
        if (reload || (this.props.location.state && this.props.location.state.reload)) {
            options.reload = true;
            this.props.history.replace({ ...this.props.location, state: {} });
        }
        fetchInterlude(
            options,
            ({ items, pagesCount, currentPageIndex }) => {
                // Ignore the results if the filtering text has already changed while fetching these.
                if (filteringText && filteringText !== this.state.filteringText) {
                    return;
                }

                const nextState = {
                    loading: false,
                    templates: items,
                    pagesCount,
                    currentPageIndex,
                };
                if (!this.totalCalculated) {
                    nextState.totalCount =
                        pagesCount > 1 ? this.state.pageSize * (pagesCount - 1) : nextState.templates.length;
                    this.totalCalculated = true;
                }
                this.setState(nextState);
            },
            (error) => {
                this.setState({ flashbar: itemError('Failed to load interludes', error), loading: false });
            },
        );
    }

    onPaginationChange(evt) {
        this.setState({
            pageSize: evt.detail.pageSize,
            currentPageIndex: evt.detail.currentPageIndex,
        });
        this.loadItems();
    }

    onSortingChange(evt) {
        this.setState({
            sortingColumn: evt.detail.sortingColumn,
            sortingDescending: evt.detail.sortingDescending,
        });
        this.loadItems();
    }

    onFilteringChange(evt) {
        this.setState({
            filteringText: evt.detail.filteringText,
            loading: true,
        });
    }

    onFilteringDelayed() {
        this.loadItems();
    }

    onSelectionChange(evt) {
        this.setState({
            selectedTemplates: evt.detail.selectedItems,
        });
    }

    onContentSelectionChange({ detail }) {
        const contentSelection = detail.contentSelection;
        const currentContentSelectorOptionGroup = this.state.contentSelectorOptions[0];
        this.setState({
            contentSelectorOptions: [
                {
                    label: currentContentSelectorOptionGroup.label,
                    options: currentContentSelectorOptionGroup.options.map((opt) => ({
                        id: opt.id,
                        label: opt.label,
                        editable: opt.editable,
                        visible: contentSelection.indexOf(opt.id) !== -1,
                    })),
                },
            ],
        });
    }

    openDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    // Handler for Items delete
    onItemsDelete = () => {
        this.setState({ deleting: true });
        const url = getAPIGEndpoint() + '/api/interludes/manage';
        const deletions = this.state.selectedTemplates.map((template) => {
            return fetch(url + '?id=' + template.id, {
                method: 'DELETE',
                headers: {
                    Authorization: Constants.auth.getSignInUserSession().getIdToken().getJwtToken(),
                },
            })
                .then((response) => {
                    if (!response.ok) throw new Error('Interludes delete failed');
                    return response.json();
                })
                .then((json) => {});
        });
        Promise.all(deletions)
            .catch((error) => {
                this.setState({ flashbar: [itemError('Failed to delete one or more interludes', error)] });
            })
            .finally(() => {
                this.setState({ deleting: false });
                this.loadItems(true);
            });
    };

    // Handler for Export to csv
    // Handler for Items delete
    onItemsExport = () => {
        this.setState({ dataToDownload: [], exporting: true });
        const url = getAPIGEndpoint() + '/api/interludes/csvexport?id=';
        fetch(url + this.state.selectedTemplates.map((interlude) => interlude.id).join(','), {
            method: 'GET',
            headers: {
                Authorization: Constants.auth.getSignInUserSession().getIdToken().getJwtToken(),
            },
        })
            .then((response) => {
                if (!response.ok) throw new Error('Interludes fetch failed');
                return response.json();
            })
            .then((json) => {
                this.setState({ dataToDownload: json }, () => {
                    // Use a timeout to allow the state to propagate to the CSVLink control before clicking the button
                    window.setTimeout(() => this.csvLink.current.link.click(), 100);
                });
            })
            .catch((error) => {
                this.setState({ flashbar: itemError('Failed to export interludes', error) });
            })
            .finally(() => this.setState({ exporting: false }));
    };

    onWrapLinesChange({ detail }) {
        this.setState({
            wrapLines: detail.value,
        });
    }

    filteringCountTextFunction() {
        const { templates, pagesCount, pageSize } = this.state;
        return getServerFilterCounterText(templates, pagesCount, pageSize);
    }

    clearFilter() {
        this.setState({
            filteringText: '',
        });
        this.loadItems();
    }

    render() {
        const disabled = this.state.loading || this.state.exporting || this.state.deleting;
        return (
            <div>
                <Modal
                    visible={this.state.showDeleteModal}
                    header="Delete Interlude Template?"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="link" onClick={() => this.setState({ showDeleteModal: false })}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    this.onItemsDelete();
                                    this.setState({ showDeleteModal: false });
                                }}
                            >
                                Confirm
                            </Button>
                        </span>
                    }
                >
                    This will delete Interlude Template and linked associations.
                </Modal>
                <Flashbar items={this.state.flashbar}></Flashbar>
                <Table
                    columnDefinitions={this.state.columnDefinitions}
                    items={this.state.templates}
                    stickyHeader={true}
                    resizableColumns={true}
                    onColumnWidthsChange={this.saveWidths}
                    header={
                        <InterludeTableButtons
                            showDeleteModal={this.openDeleteModal}
                            exportInterludes={this.onItemsExport}
                            selectedItems={this.state.selectedTemplates}
                            totalItems={this.state.totalCount}
                            updateTools={this.props.updateTools}
                            serverSide={true}
                            loading={this.state.loading}
                            onReload={() => this.loadItems(true)}
                            exporting={this.state.exporting}
                            deleting={this.state.deleting}
                        />
                    }
                    loading={this.state.loading}
                    loadingText="Loading Interludes info"
                    empty={<TableNoMatchState onClearFilter={this.clearFilter.bind(this)} />}
                    wrapLines={this.state.wrapLines}
                    onWrapLinesChange={this.onWrapLinesChange.bind(this)}
                    onContentSelectionChange={this.onContentSelectionChange.bind(this)}
                >
                    <TableFiltering
                        filteringPlaceholder="Find Interludes"
                        filteringFields={FILTERING_FIELDS}
                        filteringFunction={null}
                        filteringText={this.state.filteringText}
                        onFilteringChange={this.onFilteringChange.bind(this)}
                        onFilteringDelayedInput={this.onFilteringDelayed.bind(this)}
                        filteringCountTextFunction={this.filteringCountTextFunction.bind(this)}
                    />
                    <TablePagination
                        labels={paginationLabels}
                        currentPageIndex={this.state.currentPageIndex}
                        pagesCount={this.state.pagesCount}
                        onPaginationChange={this.onPaginationChange}
                        disabled={disabled}
                        pageSize={this.state.pageSize}
                    />
                    <TableSorting
                        sortingColumn={this.state.sortingColumn}
                        sortableColumns={sortableColumns}
                        disabled={disabled}
                        onSortingChange={this.onSortingChange.bind(this)}
                    />
                    <TableSelection
                        labels={templateSelectionLabels}
                        selectedItems={this.state.selectedTemplates}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                    />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        disabled={disabled}
                    >
                        <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS} />
                        <TableContentSelector
                            title="Select visible columns"
                            options={this.state.contentSelectorOptions}
                        />
                    </TablePreferences>
                </Table>
                <CSVLink
                    data={this.state.dataToDownload}
                    filename="Interludes.csv"
                    target="_blank"
                    className="awsui-util-hide"
                    ref={this.csvLink}
                />
            </div>
        );
    }
}

export default InterludesTable;
