import React from 'react';
import { Multiselect, Select, DatePicker, TimeInput } from '@amzn/awsui-components-react';
import {
    available_language_options,
    available_market_options,
    service_tier_options,
    day_list,
    getSelection,
    getMultipleSelection,
} from '../../configs/multi-select-config';

export interface Props {
    interlude: any;
    initial?: any;
    isEdit?: boolean;
    onChange: (e: CustomEvent) => void;
}

class Eligibility extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    handleChange = (e: CustomEvent) => {
        this.props.onChange(e);
    };

    render() {
        const startDate = this.props.interlude.start_date || '';
        const endDate = this.props.interlude.end_date || '';
        const startTime = this.props.interlude.startTime
            ? this.props.interlude.startTime
            : this.props.interlude.hourOfDayStart
            ? this.props.interlude.hourOfDayStart + ':' + this.props.interlude.minuteOfHourStart
            : '';
        const endTime = this.props.interlude.endTime
            ? this.props.interlude.endTime
            : this.props.interlude.hourOfDayEnd
            ? this.props.interlude.hourOfDayEnd + ':' + this.props.interlude.minuteOfHourEnd
            : '';
        const responsiveLabel = 'col-xxxs-12 col-xxs-4';
        const responsiveValue = 'col-xxxs-12 col-xxs-8';
        const responsiveValueHalf = 'col-xxxs-6 col-xxs-4';
        return (
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">Playback Eligibility</div>
                <div className="awsui-grid">
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Service Tiers:</h4>
                        <Multiselect
                            className={responsiveValue}
                            options={service_tier_options}
                            placeholder="Choose options"
                            checkboxes={true}
                            selectedOptions={getMultipleSelection(service_tier_options, this.props.interlude.tiers)}
                            selectedLabel="Selected"
                            id="tiers"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Available Languages:</h4>
                        <Multiselect
                            className={responsiveValue}
                            options={available_language_options}
                            placeholder="Choose options"
                            checkboxes={true}
                            selectedOptions={getMultipleSelection(
                                available_language_options,
                                this.props.interlude.lang,
                            )}
                            selectedLabel="Selected"
                            id="lang"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Available Markets:</h4>
                        <Multiselect
                            className={responsiveValue}
                            options={available_market_options}
                            placeholder="Choose options"
                            checkboxes={true}
                            selectedOptions={getMultipleSelection(
                                available_market_options,
                                this.props.interlude.mtr_list,
                            )}
                            selectedLabel="Selected"
                            id="mtr_list"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Start Date/Time:</h4>
                        <DatePicker
                            className={responsiveValueHalf}
                            placeholder="YYYY/MM/DD"
                            todayLabel="Today"
                            nextMonthLabel="Next month"
                            id="start_date"
                            value={startDate}
                            onChange={this.handleChange}
                            previousMonthLabel="Previous month"
                            disabled={!!this.props.isEdit}
                        />
                        <TimeInput
                            className={responsiveValueHalf}
                            format="hh:mm"
                            placeholder="hh:mm"
                            id="startTime"
                            value={startTime}
                            onChange={this.handleChange}
                            disabled={!!this.props.isEdit}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>End Date/Time:</h4>
                        <DatePicker
                            className={responsiveValueHalf}
                            placeholder="YYYY/MM/DD"
                            todayLabel="Today"
                            nextMonthLabel="Next month"
                            id="end_date"
                            value={endDate}
                            onChange={this.handleChange}
                            previousMonthLabel="Previous month"
                            disabled={!!this.props.isEdit}
                        />
                        <TimeInput
                            className={responsiveValueHalf}
                            format="hh:mm"
                            placeholder="hh:mm"
                            id="endTime"
                            value={endTime}
                            onChange={this.handleChange}
                            disabled={!!this.props.isEdit}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Day Of the Week Start:</h4>
                        <Select
                            className={responsiveValue}
                            options={day_list}
                            selectedOption={getSelection(day_list, this.props.interlude.dayOfWeekStart)}
                            selectedLabel="Selected"
                            id="dayOfWeekStart"
                            onChange={this.handleChange}
                            disabled={!!this.props.isEdit}
                        />
                    </div>
                    <div className="awsui-row">
                        <h4 className={responsiveLabel}>Day Of the Week End:</h4>
                        <Select
                            className={responsiveValue}
                            options={day_list}
                            selectedOption={getSelection(day_list, this.props.interlude.dayOfWeekEnd)}
                            selectedLabel="Selected"
                            id="dayOfWeekEnd"
                            onChange={this.handleChange}
                            disabled={!!this.props.isEdit}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Eligibility;
